import React, { useState, useEffect } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import { Country } from "country-state-city";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import countries from "i18n-iso-countries";
import currencyCodes from "currency-codes";
import cc from "currency-codes";
import { useDispatch, useSelector } from "react-redux";
import countryToCurrency from "country-to-currency";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const CurrencyMaster = () => {
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newCurrency, setNewCurrency] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [code, setcode] = useState("");
  const [Name, setName] = useState("");
  const [Decimal, setDecimal] = useState("");
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const state = useSelector((state) => state.changeTheState);
  //console.log(state?.user?.emailId);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  //console.log(LoggedInUserName);
  useEffect(() => {
    const countryList = Country.getAllCountries().map((country) => {
      return {
        value: country.isoCode,
        label: country.name,
      };
    });
    setCountriesOptions(countryList);
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption?.label);
    //console.log(selectedOption?.value);
    const currency = countryToCurrency[selectedOption?.value];
    setcode(currency || "Currency Not Found");
    // const fullName = currencyCodes.name(currency);
    // setName(fullName || "Currency Not Found");
    // const currency = countryToCurrency[selectedOption?.value];
    const currencyFullName =
      cc.code(currency)?.currency || "Currency Not Found";
    // setName(currencyFullName);
    setName(currencyFullName);
  };

  async function initialCall() {
    setIsLoading(true);
    try {
      const result = await axios
        // .get(`${BASEURL}/getNewCurrencyId`, {
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=377`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        });
      setNewCurrency(result?.data[0]?.NEXTCURRNO);
    } catch (err) {
      //console.log(err);
    }

    try {
      const result = await axios
        // .get(`${BASEURL}/getCurrenciesList`, {
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=334`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        });
      setCurrencyList(result?.data);
    } catch (err) {
      //console.log(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    initialCall();
  }, []);
  const filteredData = currencyList?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (
      searchData.length === 0
      // && !fromDateFilter && !toDateFilter
    ) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        // ["BILLNO"],
        ["CURRNO"],
        ["CURRCODE"],
        ["CURRNAME"],
        ["COUNTRYCODE"],
        ["DECIMALPLACES"],
        ["TRAVELSUPPLIERNAME"],
        ["TRAVELSUPPLIERNAME"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const Date = item.RECEIPTDATE;
    // if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
    //   return true;
    // }
    // const dateMatch =
    //   (!fromDateFilter || Date >= fromDateFilter) &&
    //   (!toDateFilter || Date <= toDateFilter);

    return textMatch;
    // && dateMatch;
  });
  function SaveSupplier() {
    //console.log(
    //   newCurrency,
    //   code,
    //   Name,
    //   selectedCountry,
    //   LoggedInUserName,
    //   Decimal
    // );
    if (!code || !Name || !Decimal) {
      alert("All Fields Mandatory");
      return;
    }
    setIsLoading(true);
    axios
      .post(
        // `${BASEURL}/insertCurrency`,
        `${BASEURL_GLOBAL}/globalSpHandler?spname=116 `,
        {
          CURRNO: newCurrency,
          CURRCODE: code,
          CURRNAME: Name,
          COUNTRYCODE: selectedCountry,
          DECIMALPLACES: Decimal,
          CREATEDBY: LoggedInUserName,
          SUCCESS_STATUS: "",
          ERROR_STATUS: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        alert(res.data.message);
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <Loading />;
  }
  function handleEdit(item) {
    setNewCurrency(item.CURRNO);
    setName(item.CURRNAME);
    setcode(item.CURRCODE);
    setDecimal(item.DECIMALPLACES);
    setSelectedCountry(item.COUNTRYCODE);
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <div>
      <div className="print:hidden block">
        <ManagerMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Currency Master</h1>
          <div className="lg:flex">
            <button
              className="lg:flex items-center justify-center border lg:ml-[30px] border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => window.location.reload()}
            >
              New
            </button>
            <button
              className="lg:flex items-center justify-center border lg:ml-[30px] border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300"
              onClick={async () => {
                if (!code) {
                  alert("Kindly Supply  Code");
                } else if (!Name) {
                  alert("Kindly Supply  Name");
                } else if (!Decimal) {
                  alert("Kindly Supply  Decimal Places");
                } else {
                  await SaveSupplier();
                  // window.location.reload();
                }
              }}
            >
              Save
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
        </header>
        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 lg:h-32 p-1">
          {/* Line 1 */}
          <div
            className="lg:flex mt-2 lg:ml-2"
            style={{ alignItems: "center" }}
          >
            <p>Currency Number</p>
            <input
              className="h-9 lg:ml-2 text-red-500 border-black"
              type="text"
              value={newCurrency || ""}
            />
            <p className="lg:ml-8">Country</p>
            <div className="w-[300px]">
              <Select
                className="lg:ml-2"
                value={{ value: selectedCountry, label: selectedCountry }}
                onChange={handleCountryChange}
                options={
                  Array.isArray(countriesOptions) ? countriesOptions : []
                }
                placeholder="Select a country"
              />
            </div>
            <p className="lg:ml-8">Code</p>
            <input
              className="h-9 lg:ml-2"
              type="text"
              value={code ? code : ""}
              onChange={(e) => setcode(e.target.value)}
            />
            <p className="lg:ml-8">Name</p>
            <input
              className="h-9 lg:ml-2"
              type="text"
              value={Name ? Name : ""}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="lg:flex mt-4" style={{ alignItems: "center" }}>
            <p className="lg:ml-6">Decimal Places</p>
            <input
              className="h-9 lg:ml-2"
              type="text"
              value={Decimal ? Decimal : ""}
              onChange={(e) => setDecimal(e.target.value)}
            />
          </div>
        </div>
        <div className="lg:flex mt-2 lg:ml-2 items-center p-1">
          <p>Search :</p>
          <input
            className="w-80 h-9 lg:ml-2"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="overflow-auto m-2.5 p-2.5 lg:w-[70%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                  Currency Number
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Code
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Name
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Country
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5] text-right">
                  Decimal Places
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Edit
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="w-[80px] border-l border-transparent border-r py-1 px-4 text-right">
                      {item.CURRNO || "0"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.CURRCODE || "NA"}
                    </td>
                    <td className="w-[380px] border-l border-r border-transparent">
                      {item.CURRNAME || "NA"}
                    </td>
                    <td className="w-[230px] border-l border-r border-transparent">
                      {item.COUNTRYCODE || "NA"}
                    </td>
                    <td className="w-[100px] text-right border-l border-r border-transparent">
                      {item.DECIMALPLACES || "NA"}
                    </td>
                    <td
                      className="w-[80px] border-l border-r border-transparent"
                      onClick={() => {
                        handleEdit(item);
                        scrollToSection();
                      }}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Currency Master</h1>
        </header>
        <div className="overflow-auto m-2.5 p-2.5 lg:w-[70%] rounded-md shadow-md text-xs uppercase bg-gray-100">
          <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
            <thead className="custom-table">
              <tr>
                <td className="text-black font-medium uppercase border border-[#055fc5] text-right">
                  Currency Number
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Code
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Name
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Country
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5] text-right">
                  Decimal Places
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="w-[80px] border-l border-transparent border-r py-1 px-4 text-right">
                      {item.CURRNO || "0"}
                    </td>
                    <td className="w-[280px] border-l border-r border-transparent">
                      {item.CURRCODE || "NA"}
                    </td>
                    <td className="w-[380px] border-l border-r border-transparent">
                      {item.CURRNAME || "NA"}
                    </td>
                    <td className="w-[230px] border-l border-r border-transparent">
                      {item.COUNTRYCODE || "NA"}
                    </td>
                    <td className="w-[100px] text-right border-l border-r border-transparent">
                      {item.DECIMALPLACES || "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CurrencyMaster;
