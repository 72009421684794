import React, { useState, useEffect } from "react";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import DriverNavbar from "../../Components/Navbar/DriverNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const DriverFuelVoucher = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);

  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fuelVoucherNo, setFuelVoucherNo] = useState(0); // Assuming initial value is 0
  const [fuelLocationSelect, setFuelLocationsSelect] = useState([]);
  const [fuelType, setFuelType] = useState("");
  const [unitIdFuel, setUnitIdFuel] = useState("");
  const [kmReadingGeo, setKmReadingGeo] = useState("");

  const [vehicleList, setVehicleList] = useState("");
  const [driverList, setDriverList] = useState("");
  const [driverALLdata, SETdriverALLdata] = useState("");
  const [currentDateTime, setcurrentDateTime] = useState("");
  const [vehicleNo, setVehicleNo] = useState(state?.user?.vehicle);
  const [driverCode, setDriverCode] = useState(state?.user?.userCode);
  const [driverName, setDriverName] = useState(state?.user?.userName);
  const [fuelLocation, setFuelLocation] = useState("");
  const [payMode, setPayMode] = useState("CARD");
  const [dateOfRequest, setDateOfRequest] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [fuelQty, setFuelQty] = useState(0); // Assuming initial value is 0
  const [fuelRate, setFuelRate] = useState(0); // Assuming initial value is 0
  const [fuelReqDate, setFuelReqDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [fuelReqBy, setFuelReqBy] = useState(state?.user?.userName);
  const [narration, setNarration] = useState("");
  const [FuelRequestData, setFuelRequestData] = useState([]);
  const [PreviousKmreading, setPreviousKmreading] = useState([]);
  const payModesOptions = [
    { label: "CARD ", value: "CARD" },
    { label: "CASH", value: "CASH" },
    { label: "PESAPAL", value: "PESAPAL" },
    { label: "MPESA", value: "MPESA" },
  ];

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const customStyless2 = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const [activeTab, setActiveTab] = useState(1);

  function gatFuelData() {
    setIsLoading(true);
    axios
      //   .get(`${BASEURL}/newFuelRequestId`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=384`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelVoucherNo(response?.data[0]?.FUELREQUEST);
        //console.log(response.data[0]?.FUELREQUEST, "setFuelVoucherNo");
      })
      .catch((error) => console.error(error));
    //newFuelStations
    axios
      // .get(`${BASEURL}/newFuelStations`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=369`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelLocationsSelect(
          response.data.map((item) => ({
            label: item.FUELLOCATION,
            value: item.FUELLOCATION,
          }))
        );
      })
      .catch((error) => console.error(error));
    //getVehicleList
    axios
      // .get(`${BASEURL}/getVehicleList`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=671`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleList(
          response.data.map((item) => ({
            label: item["VEHICLE REG NO"],
            value: item["VEHICLE REG NO"],
          }))
        );
        //console.log(response.data, "setVehicleList");
      })
      .catch((error) => console.error(error));
    axios
      // .get(`${BASEURL}/getEmployeeListDriver`, {
      .get(
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=611&DESIGNATION=DRIVER`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setDriverList(
          response.data.map((item) => ({
            label: item["EMPLOYEE NAME"],
            value: item["EMPLOYEE NAME"],
          }))
        );
        //console.log(response.data, "setDriverList");
        SETdriverALLdata(response.data);
      })
      .catch((error) => console.error(error));
    axios
      // .get(`${BASEURL}/getfuelvouchers`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=360`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setDriverList");
        setFuelRequestData(response.data ? response.data : "");
      })
      .catch((error) => console.error(error));
    axios
      // .post(
      //   `${BASEURL}/getlatestKMreading`,
      //   {
      //     DRIVERCODE: state?.user?.userCode,
      //   },
      .get(
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=360&DRIVERCODE=${state?.user?.userCode}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setPreviousKmreading(
          response.data ? (response.data[0]?.KM ?? 0).toFixed(2) : "0.00"
        );
      })
      .catch((error) => console.error(error));
    if (state?.user?.vehicle) {
      axios
        // .post(
        //   `${BASEURL}/getUnitID`,
        //   {
        //     VEHICLEREGNO: state?.user?.vehicle,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=671&VEHICLE REG NO=${state?.user?.vehicle}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setUnitIdFuel(response.data[0].UNITID);
          console.log(response.data[0].UNITID);
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    gatFuelData();
    setFuelReqDate(new Date().toISOString().split("T")[0]);
    setDateOfRequest(new Date().toISOString().split("T")[0]);

    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Get the current time and date
    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    // setCurrentTime(formattedTime);
    // setCurrentDate(formattedDate);
    setcurrentDateTime(formattedDateTime);
    // fetchData();
    setIsLoading(false);
  }, [unitIdFuel]);
  const handleFuelLocationChange = (selectedOption) => {
    //console.log("Selected Fuel Type:", selectedOption?.value);
    setFuelLocation(selectedOption?.value);
  };
  const selectedVehicle = (selectedVEh) => {
    //console.log("Selected selectedVEh:", selectedVEh?.value);
    setVehicleNo(selectedVEh?.value);
  };

  const handleDateOfRequestChange = (e) => {
    setDateOfRequest(e.target.value);
    setFuelReqDate(e.target.value);
  };
  const hardcodedFuelTypes = [
    { label: "FUEL SAFARI", value: "FUEL SAFARI" },
    { label: "TRANSFER", value: "TRANSFER" },
    { label: "BOTH", value: "BOTH" },
  ];

  const handlePayModeChange = (selectedOption) => {
    setPayMode(selectedOption.value);
  };
  const handleFuelTypeChange = (selectedOption) => {
    setFuelType(selectedOption.value);
  };

  async function updateStatus() {
    if (!fuelVoucherNo) {
      alert(
        "Fuel Voucher Number is empty. Please provide a value for Fuel Voucher Number."
      );
      return;
    }
    if (!kmReadingGeo) {
      alert("Fuel KM reading not available.");
      return;
    }
    if (Number(kmReadingGeo) < 1) {
      alert("Fuel KM cannot be less than 0");
      return;
    }
    if (!fuelType) {
      alert("Trip Type is empty. Please provide a value for Fuel Type.");
      return;
    }
    if (!vehicleNo) {
      alert(
        "Vehicle Number is empty. Please provide a value for Vehicle Number."
      );
      return;
    }
    if (!driverCode) {
      alert("Driver Code is empty. Please provide a value for Driver Code.");
      return;
    }
    if (!driverName) {
      alert("Driver Name is empty. Please provide a value for Driver Name.");
      return;
    }

    if (!fuelQty) {
      alert(
        "Fuel Liters Quantity is empty. Please provide a value for Fuel Quantity."
      );
      return;
    }
    if (!fuelRate) {
      alert("Fuel Rate is empty. Please provide a value for Fuel Rate.");
      return;
    }
    if (!fuelReqDate) {
      alert(
        "Fuel Request Date is empty. Please provide a value for Fuel Request Date."
      );
      return;
    }
    if (!fuelReqBy) {
      alert(
        "Fuel Request By is empty. Please provide a value for Fuel Request By."
      );
      return;
    }
    // if (!kmReading) {
    //   alert(
    //     "Kilometer Reading is empty. Please provide a value for Kilometer Reading."
    //   );
    //   return;
    // }
    if (!narration) {
      alert("Narration is empty. Please provide a value for Narration.");
      return;
    }
    if (!fuelLocation) {
      alert(
        "Fuel Location is empty. Please provide a value for Fuel Location."
      );
      return;
    }
    if (!payMode) {
      alert("Payment Mode is empty. Please provide a value for Payment Mode.");
      return;
    }

    //console.log("Request Data:", requestData);

    const confirmed = window.confirm(
      `Are you sure you want to submit the Voucher?`
    );

    if (confirmed) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          // `${BASEURL}/insertFuelRequest`,
          `${BASEURL_GLOBAL}/globalSpHandler?spname=139`,
          {
            FUELVOUCHERNO: fuelVoucherNo,
            FUELTYPE: fuelType,
            VEHICLENO: vehicleNo,
            DRIVERCODE: driverCode,
            DRIVERNAME: driverName,
            FUELLOCATION: fuelLocation,
            FUELQTY: fuelQty,
            FUELRATE: fuelRate,
            FUELREQDATE: fuelReqDate,
            FUELREQBY: fuelReqBy,
            KMREADING: kmReadingGeo ? kmReadingGeo : 0,
            NARRATION: narration,
            PAYMODE: payMode,
            SUCCESS_STATUS: "",
            ERROR_STATUS: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        //console.log(response);
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("submission canceled by the user.");
    }
    // }
  }

  //console.log(selectedItems);

  const renderList = (
    <div className="driver__fuel__media__input">
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">Fuel Voucher </h1>
        <aside className="my-1 font-semibold">
          <button
            className="w-[80px] px-2 py-1 rounded mx-1 bg-blue-300"
            onClick={() => window.location.reload()}
          >
            New
          </button>
          {/* <button className="w-[80px] rounded mx-1 bg-green-300">Print</button> */}
          <button
            onClick={updateStatus}
            className="w-[80px] px-2 py-1 bg-green-200 rounded m-2"
          >
            Save{" "}
          </button>

          <button
            className="w-[180px] px-2 py-1 rounded mx-1 bg-orange-300"
            onClick={() => navigate("/HistoricalFuelVoucher")}
          >
            View Fuel Vouchers
          </button>
        </aside>
      </header>

      <aside className="main">
        <p>
          <label>Fuel Voucher No</label>
          <input
            style={{ width: "100px", fontWeight: "700", color: "red" }}
            value={fuelVoucherNo}
            disabled
          ></input>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Driver </label>
          <div className="my-1 md:m-[5px]">
            {" "}
            <input type="text" value={state?.user?.userName} />
          </div>
          <input
            style={{ width: "100px" }}
            value={state?.user?.userCode}
            disabled
          />
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Trip Type</label>
          <div className="my-1 md:m-[5px] w-[250px] lg:w-[180px]">
            <Select
              options={hardcodedFuelTypes}
              onChange={handleFuelTypeChange}
              style={customStyless}
            />
          </div>
        </p>
        <p>
          <label>Date</label>
          <input
            type="date" // You can also use type="date" if you want a date picker
            value={dateOfRequest}
            onChange={handleDateOfRequestChange}
          />
        </p>
      </aside>
      <aside className="main">
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label className="inline-block w-[115px]">Vehicle</label>
          <div className="my-1 md:m-[5px] w-[300px]">
            {/* <Select
              style={customStyless}
              options={vehicleList}
              onChange={selectedVehicle}
            ></Select> */}
            <input type="text" value={state?.user?.vehicle}></input>
          </div>
        </p>

        <p>
          <label>Liters</label>
          <input
            type="number"
            style={{ width: "100px" }}
            onChange={(e) => {
              setFuelQty(e.target.value);
            }}
          ></input>
        </p>
        <p>
          <label>Rate</label>
          <input
            type="number"
            style={{ width: "100px" }}
            onChange={(e) => {
              setFuelRate(e.target.value);
            }}
          ></input>
        </p>

        <p>
          <label>Amount</label>
          <input
            type="number"
            style={{ width: "100px" }}
            disabled
            value={(fuelQty * fuelRate).toFixed(2)}
          ></input>
        </p>
        <p>
          <label>KM Reading</label>
          <input
            className=" w-[100px] text-right"
            type="number"
            onChange={(e) => {
              setKmReadingGeo(e.target.value);
            }}
            defaultValue={kmReadingGeo ? kmReadingGeo : "0"}
          ></input>
        </p>
      </aside>

      <main className="main_narr justify-between mt-4 mx-1">
        <div className=" lg:flex items-start mx-2 lg:mx-1">
          <p className="lg:inline-block  w-[120px]">Narration</p>
          <textarea
            className="border-black border-[1px] rounded max-w-[400px] min-w-[300px] lg:max-w-[300px] lg:min-w-[400px] min-h-[80px] max-h-[80px]"
            onChange={(e) => {
              setNarration(e.target.value);
            }}
            placeholder="Narration"
          ></textarea>
        </div>
        <div>
          <section className="lg:flex">
            <p className=" block md:flex items-center mx-2">
              <label>Fuel Location </label>
              <div className="my-1 md:m-[5px] w-[250px] lg:w-[220px]">
                {" "}
                <Select
                  options={fuelLocationSelect}
                  onChange={handleFuelLocationChange}
                  style={customStyless2}
                ></Select>
              </div>
            </p>

            <div className="ml-[10px] block md:flex items-center">
              <label>Pay Mode</label>
              <div className="my-1 md:m-[5px] w-[250px] lg:w-[140px]">
                <Select
                  options={payModesOptions}
                  onChange={handlePayModeChange}
                  value={payModesOptions.find(
                    (option) => option.value === payMode
                  )}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverFuelVoucher;
