import React, { useState, useEffect, useRef } from "react";
import Loading from "../../Components/Loading/Loading";
import axios from "axios";
import { BiSolidEditAlt } from "react-icons/bi";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader, StandaloneSearchBox } from "@react-google-maps/api";
const PointsofInterestMaster = () => {
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const state = useSelector((state) => state.changeTheState);
  //console.log(state?.user?.emailId);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  //console.log(LoggedInUserName);
  const [search, setSearch] = useState("");
  const [PointList, setPointList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [PointCode, setPointCode] = useState("");
  const [Pointname, setPointname] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [Radius, setRadius] = useState("");
  const inputRef = useRef(null);
  const [address, setAddress] = useState("");
  const [Location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: "", lng: "" });

  const geocodeAddress = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          Pointname
        )}&key=test`
      );
      if (response.data.results.length > 0) {
        //console.log(response.data.results[0]);
        const location = response.data.results[0].geometry.location;
        //console.log(location);
        setCoordinates({ lat: location.lat, lng: location.lng });
        setLatitude(location.lat);
        setLongitude(location.lng);
        //console.log({ lat: location.lat, lng: location.lng });
        //console.log(location.lat);
      } else {
        alert("No results found");
      }
    } catch (error) {
      console.error("Error fetching geocode data:", error);
      alert("Error fetching geocode data");
    }
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBeO1mBJwcnywJskeWCrCaa0-SaGg8VyFw",
    libraries: ["places"],
  });

  const searchBoxRef = useRef(null);

  const onSearchBoxLoad = (ref) => {
    searchBoxRef.current = ref;
    setLatitude("");
    setLongitude("");
  };

  const onPlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places.length > 0) {
        const location = places[0].geometry.location;
        //console.log("Selected place:", location.lat(), location.lng());
        const place = {
          name: places[0].name,
          address: places[0].formatted_address,
          lat: location.lat(),
          lng: location.lng(),
        };
        //console.log(place);
        setPointname(place.name);
        setLatitude(location.lat);
        setLongitude(location.lng);
      } else {
        setLatitude("");
        setLongitude("");
      }
    }
  };
  async function initialCall() {
    setIsLoading(true);
    try {
      const result = await axios
        // .get(`${BASEURL}/getNewCurrencyId`, {
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=377`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        });
      // setNewCurrency(result?.data[0]?.NEXTCURRNO);
    } catch (err) {
      //console.log(err);
    }

    try {
      const result = await axios
        // .get(`${BASEURL}/getPointOfInterests`, {
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=507`, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        });
      setPointList(result?.data);
      //console.log(result?.data);
    } catch (err) {
      //console.log(err);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    initialCall();
  }, []);

  const filteredData = PointList?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    if (
      searchData.length === 0
      // && !fromDateFilter && !toDateFilter
    ) {
      return true;
    }
    const textMatch = searchData.every((term) => {
      return [
        // ["BILLNO"],
        ["POINTCODE"],
        ["POINTNAME"],
        ["LATITUDE"],

        ["LONGITUDE"],

        ["RADIUS"],
        ["ADDRESS"],
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    // const Date = item.RECEIPTDATE;
    // if (searchData.length === 0 && !fromDateFilter && !toDateFilter) {
    //   return true;
    // }
    // const dateMatch =
    //   (!fromDateFilter || Date >= fromDateFilter) &&
    //   (!toDateFilter || Date <= toDateFilter);

    return textMatch;
    // && dateMatch;
  });
  function handleEdit(item) {
    //console.log(item);
    setPointCode(item.POINTCODE);
    setPointname(item.POINTNAME);
    setRadius(item.RADIUS);
    setLatitude(item.LATITUDE);
    setLongitude(item.LONGITUDE);
    setAddress(item.ADDRESS ? item.ADDRESS : "0");
  }

  function SavePointofInterest() {
    //console.log(
    //   Pointname,
    //   PointCode,
    //   Longitude,
    //   address,
    //   Radius,
    //   Latitude,

    //   LoggedInUserName
    // );
    if (!Pointname || !PointCode || !address || !Radius) {
      alert("All Fields Mandatory");
      return;
    }
    setIsLoading(true);
    axios
      .post(
        // `${BASEURL}/insertPointOfInterest`,
        `${BASEURL_GLOBAL}/globalSpHandler?spname=187 `,

        {
          POINTCODE: PointCode,
          POINTNAME: Pointname,
          POINTCREATEDBY: LoggedInUserName,
          ADDRESS: address,
          LONGITUDE: Longitude,
          LATITUDE: Latitude,
          RADIUS: Radius,
          SUCCESS_STATUS: "",
          ERROR_STATUS: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((res) => {
        //console.log(res);
        alert(res.data.message);
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
      });
  }
  if (isLoading) {
    return <Loading />;
  }
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <div>
      <div className="print:hidden block">
        <ManagerMenuNavbar />
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Points of Interest Master</h1>
          <div className="flex items-center my-1 gap-1">
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-4 py-1 focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => window.location.reload()}
            >
              New
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-4 py-1 focus:outline-none focus:ring focus:border-blue-300"
              onClick={async () => {
                if (!PointCode) {
                  alert("Kindly Supply Point Code");
                } else if (!Pointname) {
                  alert("Kindly Supply Point Name");
                } else if (!address) {
                  alert("Kinldy Supply address");
                } else if (!Radius) {
                  alert("Kinldy Supply Radius");
                } else {
                  await SavePointofInterest();
                  // window.location.reload();
                }
              }}
            >
              Save
            </button>
            <button
              className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-4 py-1 focus:outline-none focus:ring focus:border-blue-300"
              onClick={handlePrint}
            >
              Print
            </button>
          </div>
        </header>

        <div className="lg:flex lg:flex-col mt-2 bg-slate-100 h-30 p-1">
          {/* Line 1 */}
          <div className="mb-2 mt-2 lg:ml-2 ">
            <div className="lg:flex gap-[10px] mb-2 items-center">
              <p className="lg:flex items-center">
                <p className="lg:w-[80px]">Point Code </p>

                <input
                  className=" h-9 lg:ml-2 lg:w-[500px]"
                  type="text"
                  value={PointCode ? PointCode : ""}
                  onChange={(e) => setPointCode(e.target.value)}
                ></input>
              </p>
              <h2>Point Name</h2>
              {isLoaded ? (
                <StandaloneSearchBox
                  onLoad={onSearchBoxLoad}
                  onPlacesChanged={onPlacesChanged}
                >
                  <input
                    type="text"
                    placeholder="Enter your location"
                    value={Pointname ? Pointname : ""}
                    onChange={(e) => {
                      setPointname(e.target.value);
                      if (!e.target.value) {
                        setLatitude("");
                        setLongitude("");
                      }
                    }}
                    className="lg:w-[540px]"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid `,
                      height: `34px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      // boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "relative",
                      // margin: "20px auto",
                      display: "block",
                    }}
                  />
                </StandaloneSearchBox>
              ) : (
                <div>Loading...</div>
              )}
            </div>
            <div className="lg:flex gap-8">
              <p className="lg:flex items-center ">
                <p className="lg:ml-5 ">Latitude </p>
                <input
                  className=" h-9 lg:ml-2  lg:w-[190px]"
                  type="text"
                  value={Latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                ></input>
              </p>
              <p className="lg:flex items-center ">
                <p className="lg:ml-2 ">Longitude </p>
                <input
                  className=" h-9 lg:ml-2 lg:w-[190px]"
                  type="text"
                  value={Longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                ></input>
              </p>

              <p className="lg:flex items-center ">
                <p className="lg:lg:ml-6">Radius </p>
                <input
                  className=" h-9 lg:ml-2"
                  type="text"
                  value={Radius ? Radius : ""}
                  onChange={(e) => setRadius(e.target.value)}
                ></input>
              </p>
            </div>
            {/* <button
            className="lg:flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 lg:ml-7 mr-1"
            onClick={() => {
              window.location.reload();
            }}
          >
            New{" "}
          </button> */}

            <div className="lg:flex gap-4 mt-2">
              <p className="lg:flex ">
                <p className=" ">Address Description </p>
                <textarea
                  value={address ? address : ""}
                  className="lg:max-w-[500px] lg:min-w-[500px] lg:mx-1 border rounded  border-slate-800 lg:ml-2 w-[100%]"
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </p>
            </div>
            <div className="lg:flex justify-end">
              <p></p>
            </div>
          </div>
        </div>
        {/* <p className="lg:flex items-center ">
        <p className="lg:ml-2 ">Geo coordinates</p>
        <input
          className="h-9 lg:ml-2"
          type="text"
          value={coordinates.latitude}
          onChange={(e) =>
            setCoordinates({ ...coordinates, latitude: e.target.value })
          }
        />
        <input
          className="h-9 lg:ml-2"
          type="text"
          value={coordinates.longitude}
          onChange={(e) =>
            setCoordinates({ ...coordinates, longitude: e.target.value })
          }
        />
      </p> */}
        {/* <h1>Geocode Address</h1>
      <input
        type="text"
        value={Location}
        ref={inputRef}
        onChange={(e) => setLocation(e.target.value)}
        onBlur={handleBlur}
        placeholder="Enter address"
        onKeyPress={handleKeyPress}
      />
      {coordinates.lat && (
        <div>
          <p>Latitude: {coordinates.lat}</p>
          <p>Longitude: {coordinates.lng}</p>
        </div>
      )} */}

        <div className="lg:flex mt-2 lg:ml-2 items-center p-1">
          <p>Search :</p>
          <input
            className=" h-9 lg:ml-2 lg:w-[500px]"
            type="text"
            // value={search ? search : ""}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          {/* <p className="lg:ml-4 mr-4">Job Type :</p>
        <Select
          className="lg:w-64"
          value={
            selJobTypeSearch
              ? { label: selJobTypeSearch, value: selJobTypeSearch }
              : { label: "Select Job Type", value: "" }
          }
          onChange={(e) => {
            setSelJobTypeSearch(e.value);
          }}
          options={[
            { label: "Select Job Type", value: "" },
            ...jobtypeList.map((item) => ({
              label: item?.JOBTYPE,
              value: item?.JOBTYPE,
            })),
          ]}
        ></Select> */}
          {/* <p className="lg:ml-4 mr-4">Operation Type :</p>
        <Select
          className="lg:w-64"
          value={
            selOperationTypeSearch
              ? { label: selOperationTypeSearch, value: selOperationTypeSearch }
              : { label: "Select Operation Type", value: "" }
          }
          onChange={(e) => {
            setSelOperationTypeSearch(e.value);
          }}
          options={[
            { label: "Select Operation Type", value: "" },
            { label: "SERVICE", value: "SERVICE" },
            { label: "STANDARD", value: "STANDARD" },
          ]}
        ></Select> */}
        </div>
        <div class="overflolg:w-auto m-2.5 p-2.5 ov rounded-md shadolg:w-md overflow-auto text-xs uppercase bg-gray-100">
          <table className="custom-table lg:w-[90%] m-1 border-separate border-spacing-0 overflow-auto rounded-md">
            <thead className="header-cell">
              <tr>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Point code
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Point name
                </td>
                <td className="bg-[#43505f] text-right text-white border border-[#055fc5]">
                  Latitude
                </td>
                <td className="bg-[#43505f] text-right text-white border border-[#055fc5]">
                  LONGITUDE
                </td>
                <td className="bg-[#43505f] text-right text-white border border-[#055fc5]">
                  RADIUS
                </td>
                <td className="bg-[#43505f] text-white border border-[#055fc5]">
                  Address Description
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Edit
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="lg:w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.POINTCODE ? item.POINTCODE : "NA"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent">
                      {item.POINTNAME ? item.POINTNAME : "NA"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent text-right">
                      {item.LATITUDE ? item.LATITUDE : "0"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent text-right">
                      {item.LONGITUDE ? item.LONGITUDE : "0"}
                    </td>
                    <td className="lg:w-[280px] text-right border-l border-r border-transparent">
                      {item.RADIUS ? item.RADIUS : "0"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent">
                      {item.ADDRESS ? item.ADDRESS : "NA"}
                    </td>
                    <td
                      className="lg:w-[80px] border-l border-r border-transparent"
                      onClick={() => {
                        scrollToSection();
                        handleEdit(item);
                      }}
                    >
                      <BiSolidEditAlt />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page">Points of Interest Master</h1>
        </header>
        <div class="overflolg:w-auto m-2.5 p-2.5 ov rounded-md shadolg:w-md overflow-auto text-xs uppercase bg-gray-100">
          <table className="custom-table lg:w-[90%] m-1 border-separate border-spacing-0 overflow-auto rounded-md">
            <thead className="custom-table ">
              <tr>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Point code
                </td>
                <td className="text-black font-medium uppercaseborder border-[#055fc5]">
                  Point name
                </td>
                <td className="text-black font-medium uppercase  border border-[#055fc5]">
                  Latitude
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  LONGITUDE
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  RADIUS
                </td>
                <td className="text-black font-medium uppercase border border-[#055fc5]">
                  Address Description
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData?.map((item, index) => (
                  <tr
                    key={index}
                    className="table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62] cursor-pointer"
                    style={{ textTransform: "uppercase" }}
                  >
                    <td className="lg:w-[280px] border-l border-transparent border-r py-1 px-4">
                      {item.POINTCODE ? item.POINTCODE : "NA"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent">
                      {item.POINTNAME ? item.POINTNAME : "NA"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent text-right">
                      {item.LATITUDE ? item.LATITUDE : "0"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent text-right">
                      {item.LONGITUDE ? item.LONGITUDE : "0"}
                    </td>
                    <td className="lg:w-[280px] text-right border-l border-r border-transparent">
                      {item.RADIUS ? item.RADIUS : "0"}
                    </td>
                    <td className="lg:w-[280px] border-l border-r border-transparent">
                      {item.ADDRESS ? item.ADDRESS : "NA"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PointsofInterestMaster;
