import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Loading from "../../Components/Loading/Loading";
import { useSelector } from "react-redux";

const MakeandModelMaster = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(true);
  const [materVehicleModel, setMasterVehicleModel] = useState([]);
  const [makeandModel, setMakeAndModel] = useState([]);
  const [modelVehicle, setModelVehicle] = useState([]);
  const [makeVehicle, setMakeVehicle] = useState([]);
  const [makeName, setmakeName] = useState("");
  const [modelName, setModelName] = useState("");
  const [datePush, setDatePush] = useState(
    new Date()?.toISOString()?.slice(0, 10)
  );

  const filteredData = makeandModel?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return ["MODEL", ["MAKE"]].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    return textMatch;
  });

  const confirmSave = () => {
    return window.confirm("Are you sure you want to save the values?");
  };

  async function submitCategory() {
    setisloading(true);
    // Check if required fields are filled
    if (!makeName || !modelName || !datePush || !LoggedInUserName) {
      let missingFields = [];

      if (!makeName) missingFields.push("Make Name");
      if (!modelName) missingFields.push("Model Name");
      if (!datePush) missingFields.push("Date");
      if (!LoggedInUserName) missingFields.push("Processed By");

      alert(
        `Please fill in the following fields:\n${missingFields.join("\n")}`
      );
      return;
    }
    if (!confirmSave()) {
      return; // Don't proceed if user cancels
    }
    const datePassingFormat =
      datePush.slice(8, 10) +
      "/" +
      datePush.slice(5, 7) +
      "/" +
      datePush.slice(0, 4);
    //console.log(makeName, modelName, LoggedInUserName, datePassingFormat);
    try {
      const response = await axios.post(
        // `${BASEURL}/insertVehicleMakeandModel`,
        `${BASEURL_GLOBAL}/globalSpHandler?spname=231 `,

        {
          MAKE: makeName,
          MODEL: modelName,
          DATE: datePassingFormat,
          PROCESSEDBY: LoggedInUserName,
          SUCCESS_STATUS: "",
          ERROR_STATUS: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert(response.data.message);
        setisloading(false);
        window.location.reload();
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      alert(error.response.data.message);
      console.error(`Error while saving Category`, error);
    }
  }

  useEffect(() => {
    masterData();
  }, []);

  function masterData() {
    axios
      .get(
        // `${BASEURL}/getVehiclemakelist`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=672`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setMakeVehicle(response?.data);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error While fetching Make List", err);
      });

    axios
      .get(
        // `${BASEURL}/getVehiclemodelList`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=673`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setModelVehicle(response?.data);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error while fetching Model List", err);
      });

    axios
      .get(
        // `${BASEURL}/getVehicleMakeAndModelList`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=459`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setMakeAndModel(response?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error while fetching Model List", err);
      });
  }
  const handleMakeChange = (selectedOption) => {
    if (selectedOption) {
      setmakeName(selectedOption.value);
    } else {
      setmakeName(""); // Clear make name if no option is selected
    }
  };
  const handleModelChange = (selectedOption) => {
    if (selectedOption) {
      setModelName(selectedOption.value);
    } else {
      setModelName(""); // Clear make name if no option is selected
    }
  };

  const handleRowClick = (rowData) => {
    setmakeName(rowData.MAKE);
    setModelName(rowData.MODEL);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      {" "}
      <div className="block print:hidden">
        <ManagerMenuNavbar />{" "}
        <header className="header__of__main lg:flex justify-between items-end px-3">
          <h1 className="header__of__page"> Make and Model Master</h1>
          <div className="my-1">
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={() => {
                window.location.reload();
              }}
            >
              New
            </button>
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={submitCategory}
            >
              Save
            </button>
            <button
              className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
        </header>
        <section className=" shadow-slate-100	m-2 bg-gray-100 border-black rounded	p-2 ">
          <aside className="lg:flex items-center lg:my-1  lg:mb-3">
            <p className="w-[110px]">Make Vehicle</p>
            <div>
              <Select
                className="w-[200px] lg:mr-[50px] text-sm"
                options={makeVehicle?.map((make) => ({
                  value: make.MAKE,
                  label: make.MAKE,
                }))}
                value={
                  makeName
                    ? { label: makeName, value: makeName }
                    : { label: "Select Make", value: "" }
                }
                onChange={handleMakeChange}
                placeholder="Select Make"
              />
            </div>
            <p className="">Model &nbsp;</p>
            <div>
              <Select
                className="w-[300px] lg:mr-[50px] text-sm"
                options={modelVehicle.map((model) => ({
                  value: model.MODEL,
                  label: model.MODEL,
                }))}
                value={
                  modelName
                    ? { label: modelName, value: modelName }
                    : { label: "Select Model", value: "" }
                }
                onChange={handleModelChange}
                placeholder="Select model"
              />
            </div>
            <p>Date </p>
            <input
              type="date"
              className="w-[130px] lg:mx-2"
              value={datePush}
              onChange={(e) => {
                setDatePush(e.target.value);
              }}
            ></input>{" "}
          </aside>
        </section>
        <article>
          <div>
            <section className="table__pos__app w-[98%] px-3  ">
              <div className="lg:flex">
                <p className="lg:mx-2 text-lg">Search</p>
                <input
                  className="w-[200px] lg:mr-[50px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
              </div>
              <table class="custom-table ">
                <thead class="header-cell">
                  <tr>
                    <td> SNo</td>
                    <td> VEHICLE MAKE</td>
                    <td> VEHICLE MODEL</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData.map((value, index) => (
                      <tr
                        key={index}
                        className="table-row cursor-pointer"
                        onClick={() => handleRowClick(value)}
                      >
                        <td className="text-right">{index + 1}</td>{" "}
                        {/* Add 1 to start index from 1 */}
                        <td>{value.MAKE}</td>
                        <td>{value.MODEL}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </div>
        </article>
      </div>
      <div className="hidden print:block">
        <h1 className="text-2xl mb-2">Make and Model Master</h1>
        <table class="custom-table ">
          <thead class="header-cell" className="text-black">
            <tr>
              <td> SNo</td>
              <td> VEHICLE MAKE</td>
              <td> VEHICLE MODEL</td>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData.map((value, index) => (
                <tr
                  key={index}
                  className="table-row cursor-pointer"
                  onClick={() => handleRowClick(value)}
                >
                  <td className="text-right">{index + 1}</td>{" "}
                  {/* Add 1 to start index from 1 */}
                  <td>{value.MAKE}</td>
                  <td>{value.MODEL}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MakeandModelMaster;
