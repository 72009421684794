import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const DriverAttendenceHistory = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [driverAllTrip, setDriverAllTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [attendanceDate, setAttendenceDate] = useState("");
  const [fuelLocationSelect, setFuelLocationsSelect] = useState([]);

  const [unitIdFuel, setUnitIdFuel] = useState("");
  const [kmReadingGeo, setKmReadingGeo] = useState("");

  const [kenyaDateTimeShow, setKenyaDateTimeShow] = useState("");

  const [currentDateTime, setcurrentDateTime] = useState("");
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  //console.log(kmReadingGeo, "kmReadingGeo");
  const statusOptions = [
    { value: "", label: "ALL" },
    { value: "YES", label: "YES" },
    { value: "NO", label: "NO" },
  ];

  const getStatus = (item) => {
    if (item.EXITED === true) {
      return "YES";
    } else if (item.EXITED === false) {
      return "NO";
    } else {
      return "ALL";
    }
  };

  useEffect(() => {
    driverTrip();
    gatFuelData();
    const currentDate = new Date().toISOString();
    //console.log("Using user's system datetime:", currentDate);
    setKenyaDateTimeShow(currentDate);

    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Get the current time and date
    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    setcurrentDateTime(formattedDateTime);
    // fetchData();
    setIsLoading(false);
  }, [unitIdFuel]);

  const calculateTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) return "IN PROGRESS";

    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);

    const differenceInMilliseconds = end - start;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

    return `${hours}:${minutes}:${seconds}`;
  };
  // Filtered data based on search and status
  const filteredData = driverAllTrip.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return [
          "KMREADINGSTARTDAY",
          "KMREADINGDAYEND",
          "ATTENDANCEDATE",
          "ARRIVALTIME",
          "DAYENDTIME",
          "EXITED",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const statusMatch =
      selectedStatus && selectedStatus.value !== ""
        ? getStatus(item) === selectedStatus.value
        : true;

    return textMatch && statusMatch;
  });
  const driverTrip = () => {
    if (state?.user?.designation === "DRIVER") {
      setIsLoading(true);

      axios
        // .post(
        //   `${BASEURL}/driverRepAttendance`,

        //   {
        //     EMPCODE: state?.user?.userCode,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=344&EMPCODE=${state?.user?.userCode}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )

        .then((response) => {
          setDriverAllTrips(response.data);

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (state?.user?.vehicle) {
      axios
        // .post(
        //   `${BASEURL}/getUnitID`,
        //   {
        //     VEHICLEREGNO: state?.user?.vehicle,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=671&VEHICLE REG NO=${state?.user?.vehicle}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )

        .then((response) => {
          setUnitIdFuel(response.data[0].UNITID);

          //console.log(response.data[0].UNITID, "UNITID");
        })

        .catch((error) => console.error(error));
    }
  };
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const handleRowCheck = (index) => {
    if (!checkedRows.includes(index)) {
      setCheckedRows([...checkedRows, index]);
    } else {
      setCheckedRows(checkedRows.filter((rowIndex) => rowIndex !== index));
    }
  };

  const handleClockOut = () => {
    const checkedRowData = checkedRows.map((index) => filteredData[index]);
    if (checkedRows.length === 0) {
      alert("Please select at least one row to clock out.");
      return;
    }
    //console.log("Checked rows data:", checkedRowData);
    checkedRows.forEach((index) => {
      const rowData = filteredData[index];
      //console.log(rowData.ARRIVALTIME);
      //console.log(rowData.ATTENDANCEDATE, "ATTENDANCEDATE");
      setAttendenceDate(rowData.ATTENDANCEDATE);
      if (rowData.ATTENDANCEDATE) {
        fetchKmDataToPass("END", "Clock Out", rowData.ATTENDANCEDATE);
      }
    });
  };
  function gatFuelData() {
    setIsLoading(true);

    if (state?.user?.vehicle) {
      axios
        // .post(
        //   `${BASEURL}/getUnitID`,
        //   {
        //     VEHICLEREGNO: state?.user?.vehicle,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=671&VEHICLE REG NO=${state?.user?.vehicle}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setUnitIdFuel(response.data[0].UNITID);
          //console.log(response.data[0].UNITID, "VEHICLEREGNO");
          setIsLoading(false);
        })
        .catch((error) => console.error(error));
    }
  }

  async function fetchKmDataToPass(status, messageInOut, attendanceDate) {
    const currentDate = new Date();
    const formattedDateTOPASS = currentDate?.toLocaleDateString("en-GB"); // Adjust locale as needed
    const formattedTimeTOPASS = currentDate?.toLocaleTimeString("en-GB"); // Adjust locale as needed

    if (Number(kmReadingGeo) < 1) {
      alert("Fuel KM cannot be less than 0");
      return;
    }
    if (kmReadingGeo) {
      const confirmed = window.confirm(
        `Are you sure you want to ${messageInOut}?`
      );

      if (confirmed) {
        setIsLoading(true);

        try {
          const response = await axios.post(
            `${BASEURL}/insertDriverRepAttendance`,
            {
              EMPCODE: state?.user?.userCode,
              DATE: formattedDateTOPASS,
              VEHICLE: state?.user?.vehicle,
              STATUS: status, // Passed status parameter here
              KMREADING: kmReadingGeo ? kmReadingGeo : 0,
              TIME: formattedTimeTOPASS,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          alert(response.data.message);
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert("Submission canceled by the user.");
      }
    } else {
      alert("Fetching latest km reading try again.");
    }
  }

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page"> Attendance History</h1>
        <h2 className="text-white">
          <button
            onClick={() => {
              navigate("/DriverAttendence");
            }}
            style={{ backgroundColor: "#2f2f5a", color: "white" }}
            className="button__logout__navbar mb-6 text-white p-1 rounded my-1"
          >
            BACK
          </button>
        </h2>
      </header>

      <div className="m-4">
        <div className="m-1 md:m-4 md:flex gap-4 md:items-center">
          <div className="searchhhhhh mx-4">
            <p>Search : </p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          {checkedRows > 0 && (
            <div className="lg:flex items-center gap-2 my-1">
              <h2 className="">KM Reading : </h2>

              <input
                className=" w-[150px] text-right"
                type="number"
                onChange={(e) => {
                  setKmReadingGeo(e.target.value);
                }}
                defaultValue={kmReadingGeo ? kmReadingGeo : "0"}
              ></input>
            </div>
          )}

          <div>
            <button
              style={{ backgroundColor: "#2f2f5a", color: "white" }}
              className="  text-white py-1  px-2 rounded "
              onClick={handleClockOut}
            >
              CLOCKOUT
            </button>
          </div>
        </div>
      </div>
      <aside className="table__reference__app ">
        <table className="custom-table text-[14px]">
          <thead className="header-cell">
            <td>DATE</td>
            <td></td>
            <td>CLOCK IN</td>
            <td>CLOCK OUT</td>
            <td> WORKED HOURS</td>
            <td>START KM</td>
            <td>END KM</td>
            <td> KM COVERED</td>
            <td>EXITED</td>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                return (
                  <tr key={index} className="table-row cursor-pointer">
                    <td className="text-right">
                      {item.ATTENDANCEDATE
                        ? `${formattedDate(item.ATTENDANCEDATE)?.slice(0, 10)} `
                        : "N/A"}
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={checkedRows.includes(index)}
                        onChange={() => handleRowCheck(index)}
                        disabled={item.EXITED}
                      />
                    </td>
                    <td className="text-right">
                      {item.ARRIVALTIME
                        ? `${item?.ARRIVALTIME?.slice(11, 19)}`
                        : "N/A"}
                    </td>
                    <td className="text-right">
                      {item.DAYENDTIME
                        ? `${item?.DAYENDTIME?.slice(11, 19)}`
                        : "N/A"}
                    </td>
                    <td className="text-right">
                      {calculateTimeDifference(
                        item?.ARRIVALTIME?.slice(11, 19),
                        item?.DAYENDTIME?.slice(11, 19)
                      )}
                    </td>
                    <td className="text-right">
                      {item.KMREADINGSTARTDAY ? item.KMREADINGSTARTDAY : "N/A"}
                    </td>
                    <td className="text-right">
                      {item.KMREADINGDAYEND ? item.KMREADINGDAYEND : "N/A"}
                    </td>
                    <td className="text-right">
                      {item.KMREADINGDAYEND
                        ? item.KMREADINGDAYEND - item.KMREADINGSTARTDAY
                        : "IN PROGRESS"}
                    </td>
                    <td>{item.EXITED === true ? "Yes" : "No"}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="11">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </aside>
    </div>
  );

  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverAttendenceHistory;
