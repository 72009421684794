import React, { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./EmployeeMaster.css";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import Loading from "../../Components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { BiSolidEditAlt } from "react-icons/bi";
const EmployeeMaster = () => {
  const [isloading, setisloading] = useState(true);
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const [search, setSearch] = useState("");
  const useSelect = (initialState) => {
    const [selectedOption, setSelectedOption] = useState(initialState);

    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption.value);
    };

    return [selectedOption, handleSelectChange];
  };
  const [FilterDesignation, setFilterDesignation] = useState("");
  const [FilterDepartment, setFilterDepartment] = useState("");
  const state = useSelector((state) => state.changeTheState);
  //console.log(state?.user?.emailId);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  //console.log(LoggedInUserName);
  const navigate = useNavigate();
  // const axiosJWT = JwtRequestHandler();
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  // const BASEURL = import.meta.env.VITE_REACT_APP_BASEURL;
  const initialValues = {
    EMPCODE: "",
    EMPNAME: "",
    ACCOUNTNO: "",
    // JOINDATE: "",
    NATIONALID: "",
    TELEPHONE: "",
    EMAIL: "",
    OVERTIME: 0,
    FREEZE: 0,

    CASUAL: 1, // Default to Yes (1)
    SUBCONTRACTED: 0, // Default to No (0)

    // IMAGENAME: "",
    CONFIRM: 0,
    NOTES: "",
    PORTPASS: 0,
    PORTPASSNO: "",
    PORTPASSEXPIRY: "",
  };

  const [formInput, setFormInput] = useState(initialValues);
  const [JOINDATE, setJOINDATE] = useState("");
  const [PORTPASSPORTPASSEXPIRY, setPORTPASSPORTPASSEXPIRY] = useState("");
  const [section1, setSection1] = useState(true);
  const [desigVal, setDesigVal] = useState([]);
  const [SupplierVal, setSupplierVal] = useState([]);
  const [DEPARTMENTVal, setDEPARTMENTVal] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [dispPicture, setDispPicture] = useState("");
  const [picture, setPicture] = useState(null);
  const [cameraOn, setCameraOn] = useState(false);
  const [captured, setCaptured] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [fileUpload, setFileUpload] = useState(null);
  const [filePreview, setFilePreview] = useState(false);
  const [hide, sethide] = useState(true);
  const [empidPass, setEmpIdList] = useState([]);
  const [designation, setDesignation] = useSelect("");
  const [supplier, setSupplier] = useSelect("");
  console.log(supplier);
  const [department, setDepartment] = useSelect("");
  const [vehicleAllocated, setVehicleAllocated] = useSelect("");
  const [previousDesignation, setPreviousDesignation] = useState("");
  const filteredData = empidPass?.filter((item) => {
    //console.log(item.DESIGNATION);
    //console.log(item.DEPARTMENT);
    //console.log(FilterDesignation);
    //console.log(FilterDepartment);
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);

    // If no search term, designation filter, or department filter, return true
    if (searchData.length === 0 && !FilterDesignation && !FilterDepartment) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        " EMPLOYEE ID",
        "EMPLOYEE CODE",
        "EMPLOYEE NAME",
        "NATIONAL ID NO",
        "TELEPHONE NO",
        "OVERTIME",
        "EMPLOYEE FREEZE",
        "DESIGNATION",
        "DEPARTMENT",
        "VEHICLE ALLOCATED",
        "EMAIL",
      ].some((key) => {
        const columnValue = String(item[key])?.toLowerCase() || "";
        return columnValue.includes(term);
      });
    });

    const designationMatch = FilterDesignation
      ? item.DESIGNATION?.toLowerCase() === FilterDesignation.toLowerCase()
      : true;

    const departmentMatch = FilterDepartment
      ? item.DEPARTMENT?.toLowerCase() === FilterDepartment.toLowerCase()
      : true;

    return textMatch && designationMatch && departmentMatch;
  });

  // JSX code remains the same

  console.log(filteredData);
  // Reset vehicleAllocated if designation changes
  useEffect(() => {
    if (previousDesignation && designation !== "DRIVER") {
      // setVehicleAllocated("");
    }
    setPreviousDesignation(designation);
  }, [designation, previousDesignation]);
  //console.log(designation, department, vehicleAllocated, LoggedInUserName);
  //console.log(vehicleAllocated === undefined ? null : vehicleAllocated);
  const handleInputItem = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    //console.log(formInput);
  };
  function masterData() {
    setisloading(true);
    //
    //
    axios
      // .get(`${BASEURL}/getEmployeeLists`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=611`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);

        //console.log(response.data);
        setEmpIdList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //
    axios
      .get(
        // `${BASEURL}/getVehicles`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=556`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        //console.log(response.data, "getListOfVehicles");
        setVehicles(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //
    //
    axios
      .get(
        // `${BASEURL}/getDepartments`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=347`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        //console.log(response.data, "getEmployeeDepartment");
        setDEPARTMENTVal(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    //
    //
    axios
      .get(
        // `${BASEURL}/getDesignations`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=348`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setDesigVal(response.data);
        //console.log(response.data, "getEmployeeDesignation");
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(
        // `${BASEURL}/getSupplierList`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=419`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setisloading(false);
        setSupplierVal(response.data);
        console.log(response.data, "getSupplierList");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    masterData();
  }, []);

  function tofixnumber(e) {
    const input = e.target;
    const TELEPHONE = input.value.replace(/\D/g, "");

    if (TELEPHONE.length > 15) {
      const givenvalue = TELEPHONE.replace(/(\d{15})/);
      input.value = givenvalue;
    }
  }

  const openCamera = async () => {
    setSection1(false);
    setCameraOn(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      // Handle the error or show a message to the user
    }
  };

  const capturePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !video.srcObject) {
      return;
    }

    // Rest of the code for capturing the picture
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    const pictureData = canvas.toDataURL();
    canvas.toBlob((blob) => {
      setPicture(blob);
    });
    setDispPicture(pictureData);

    // Stop video tracks
    const videoTracks = video.srcObject.getTracks();
    videoTracks.forEach((track) => track.stop());

    setCaptured(true);
    setCameraOn(false);
  };

  const retakePicture = () => {
    setPicture(null);
    setDispPicture("");
    setCaptured(false);
    openCamera();
  };
  function handlePictureUpload(e) {
    const file = e.target.files[0];
    setPicture(file);
    //console.log(file.name);

    const imageURL = URL.createObjectURL(file);
    setDispPicture(imageURL);
  }
  function handleRegister(e) {
    e.preventDefault();
    //console.log(e);
    if (picture === "" || picture === null) {
      alert("Upload image");
      return;
    }
    //console.log(
    //   formInput.EMPNO,
    //   "formInput.EMPNO",
    //   Number(formInput.CONFIRM),
    //   "Number(formInput.CONFIRM)"
    // );
    // //console.log(picture.name, "picture.EMPNO");

    //console.log(formInput);
    //console.log(
    //   empidPass,
    //   JOINDATE,
    //   PORTPASSPORTPASSEXPIRY,
    //   picture,
    //   LoggedInUserName,
    //   designation,
    //   department,
    //   vehicleAllocated
    // );
    const vehiclepush =
      vehicleAllocated === undefined ? null : vehicleAllocated;

    setisloading(true);
    const formData = new FormData();
    //console.log(formData);
    console.log(supplier);
    formData.append("EMPNO", formInput.EMPNO);
    formData.append("EMPCODE", formInput.EMPCODE);
    formData.append("EMPNAME", formInput.EMPNAME);
    formData.append("ACCOUNTNO", "");
    formData.append("NATIONALID", formInput.NATIONALID);
    formData.append("TELEPHONE", formInput.TELEPHONE);
    formData.append("EMAIL", formInput.EMAIL);
    formData.append("OVERTIME", formInput.OVERTIME);
    formData.append("DESIGNATION", designation);
    formData.append("DEPARTMENT", department);
    formData.append("FREEZE", Number(formInput.FREEZE));
    formData.append("VEHICLEALLOCATED", vehiclepush);
    formData.append("CASUAL", Number(formInput.CASUAL));
    formData.append("SUBCONTRACTED", Number(formInput.SUBCONTRACTED));
    formData.append("CREATEDBY", LoggedInUserName);
    formData.append("IMAGENAME", picture?.name);
    formData.append("CONFIRM", 0);
    formData.append("NOTES", formInput.NOTES);
    formData.append("PORTPASS", "");
    formData.append("PORTPASSNO", "");
    formData.append("JOINDATE", JOINDATE);
    formData.append("PORTPASSEXPIRY", "");
    formData.append("TRAVELSUPPLIER", supplier);
    formData.append("imageValue", picture);
    axios
      .post(`${BASEURL}/insertEmployee`, formData)
      .then((response) => {
        if (response.data.message === "niet") {
          alert("Employee code already exists!");
          setisloading(false);
        } else {
          alert(response.data.message);
          setisloading(false);
          // navigate("/empdetails");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const renderFilePreview = () => {
    if (fileUpload) {
      const fileType = fileUpload.type.split("/")[1];

      if (fileType === "pdf") {
        return (
          <embed
            src={URL.createObjectURL(fileUpload)}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        );
      } else if (
        ["jpg", "jpeg", "png", "heic", "heif", "webp"].includes(fileType)
      ) {
        return <img src={URL.createObjectURL(fileUpload)} />;
      }

      return <p>Preview not available for this file type.</p>;
    }
  };
  // if (isloading) {
  //   return <Loading />;
  // }
  const handleEdit = (employee) => {
    //console.log(employee);
    setFormInput({
      EMPNO: employee[" EMPLOYEE ID"],
      EMPCODE: employee["EMPLOYEE CODE"] || "",
      EMPNAME: employee["EMPLOYEE NAME"] || "",
      // ACCOUNTNO: employee["EMPLOYEE NAME"] || "",
      NATIONALID: employee["NATIONAL ID NO"] || "",
      TELEPHONE: employee["TELEPHONE NO"] || "",
      EMAIL: employee?.EMAIL,
      OVERTIME: employee.OVERTIME ? 1 : 0,
      FREEZE: employee["EMPLOYEE FREEZE"],
      CASUAL: employee.CASUAL ? 1 : 0,
      SUBCONTRACTED: employee.SUBCONTRACTED ? 1 : 0,
      CONFIRM: employee.CONFIRM,
      // VEHICLEALLOCATED: employee["VEHICLE ALLOCATED"] || "",
      // PORTPASSEXPIRY: employee["EXPIRY DATE"] || "",
      // NOTES: employee.NOTES || "",
      // PORTPASS: employee.PORTPASS || 0,
      // PORTPASSNO: employee.PORTPASSNO || "",
    });
    setJOINDATE(
      employee["DATE JOINED"]
        ? moment(employee["DATE JOINED"]).format("YYYY-MM-DD")
        : ""
    );
    // setPORTPASSPORTPASSEXPIRY(
    //   employee["EXPIRY DATE"]
    //     ? moment(employee["EXPIRY DATE"]).format("YYYY-MM-DD")
    //     : ""
    // );
    setDesignation({
      value: employee["DESIGNATION"],
      label: employee["DESIGNATION"],
    });
    setSupplier({
      value: employee["SUPPLIERCODE"],
      label: employee["SUPPLIERCODE"],
    });
    setDepartment({
      value: employee["DEPARTMENT"],
      label: employee["DEPARTMENT"],
    });
    setVehicleAllocated({
      value: employee["VEHICLE ALLOCATED"],
      label: employee["VEHICLE ALLOCATED"],
    });
    // setDispPicture(
    //   employee.IMAGENAME ? `${BASEURL}/images/${employee.IMAGENAME}` : ""
    // );
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePrint = () => {
    window.print();
  };
  return (
    <div className="bg-slate-100">
      <div className="print:hidden block">
        <ManagerMenuNavbar />

        {cameraOn ? (
          <div className="camera__align">
            <video ref={videoRef} />

            <button
              className="Capture"
              onClick={() => {
                capturePicture();
              }}
            >
              Capture
            </button>
            <canvas ref={canvasRef} />
          </div>
        ) : null}
        {captured ? (
          <div>
            <div>
              <img className="Image" src={dispPicture} />
            </div>

            <div className="camera_btn2">
              <button
                className="retake"
                onClick={() => {
                  retakePicture();
                }}
              >
                Retake
              </button>
              <button
                className="Proceed"
                onClick={() => {
                  setCameraOn(false);
                  setCaptured(false);
                  setSection1(true);
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        ) : null}

        {section1 ? (
          <div className="lg:w-[800px] w-[100%] mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-6">Employee Master</h2>
            <form onSubmit={handleRegister}>
              <div className="row">
                <div className="small-12 medium-2 large-2 columns">
                  <div className="rounded-[20%] w-[200px] h-[200px] shadow-lg bg-slate-50 py-4 px-2">
                    <img
                      required
                      name="userImg"
                      style={{ fontFamily: "Cursive" }}
                      src={dispPicture}
                      alt="Upload image here"
                      className={dispPicture ? "h-[150px] rounded" : ""}
                    />
                  </div>
                  <div className="camera_upload">
                    <div className="camera__icon">
                      <button
                        className="camera_btn"
                        onClick={() => {
                          const CONFIRMBox = window.confirm(
                            "Allow access to camera?"
                          );
                          if (CONFIRMBox === true) {
                            setSection1(false);
                            setCameraOn(true);
                            openCamera();
                          } else {
                            return null;
                          }
                        }}
                      >
                        {" "}
                        <p className="bg-green">
                          <FaCamera />
                        </p>
                      </button>
                    </div>
                    <div>
                      <input
                        style={{ padding: "5px", display: "none" }}
                        id="file"
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => {
                          handlePictureUpload(e);
                        }}
                      />
                      <div className="bg-black text-white px-3 py-1 rounded-md cursor-pointer">
                        <label
                          className="View upload__file cursor-pointer"
                          htmlFor="file"
                        >
                          Upload
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE NO{" "}
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    name="EMPNO"
                    onChange={(e) => handleInputItem(e)}
                    defaultValue={formInput.EMPNO}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE CODE{" "}
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    name="EMPNO"
                    defaultValue={formInput.EMPCODE}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMPLOYEE NAME
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                    name="EMPNAME"
                    defaultValue={formInput.EMPNAME}
                    onChange={(e) => handleInputItem(e)}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    JOIN DATE
                  </label>
                  <input
                    required
                    type="date"
                    name="JOINDATE"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    defaultValue={JOINDATE}
                    max={new Date().toISOString().split("T")[0]}
                    onChange={(e) => {
                      setJOINDATE(moment(e.target.value).format("YYYY/MM/DD"));
                    }}
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    NATIONAL ID
                  </label>
                  <input
                    required
                    type="text"
                    name="NATIONALID"
                    defaultValue={formInput.NATIONALID}
                    onChange={(e) => handleInputItem(e)}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    TELEPHONE
                  </label>
                  <input
                    required
                    type="number"
                    name="TELEPHONE"
                    defaultValue={formInput.TELEPHONE}
                    onChange={(e) => handleInputItem(e)}
                    onInput={tofixnumber}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    EMAIL
                  </label>
                  <input
                    required
                    type="EMAIL"
                    name="EMAIL"
                    defaultValue={formInput.EMAIL}
                    onChange={(e) => handleInputItem(e)}
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%] z-[900]">
                  <label className="block text-gray-700 font-bold mb-2">
                    DESIGNATION{" "}
                  </label>
                  <Select
                    className="w-full"
                    required
                    // defaultValue={designation}
                    value={
                      designation
                        ? { label: designation, value: designation }
                        : { label: "select", value: "" }
                    }
                    onChange={setDesignation}
                    options={desigVal.map((data) => ({
                      label: data.OM_DESIGNATION_NAME,
                      value: data.OM_DESIGNATION_NAME,
                    }))}
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%] z-[900]">
                  <label className="block text-gray-700 font-bold mb-2">
                    SUPPLIER{" "}
                  </label>
                  <Select
                    className="w-full"
                    required
                    // defaultValue={designation}
                    value={
                      supplier
                        ? { label: supplier, value: supplier }
                        : { label: "select", value: "" }
                    }
                    onChange={setSupplier}
                    options={SupplierVal.map((data) => ({
                      label: data.TRAVELSUPPLIERNAME,
                      value: data.TRAVELSUPPLIERNAME,
                    }))}
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4 lg:w-[48%] w-[90%] z-[800]">
                  <label className="block text-gray-700 font-bold mb-2">
                    DEPARTMENT{" "}
                  </label>
                  <Select
                    className="w-full"
                    required
                    // defaultValue={department}
                    value={
                      department
                        ? { label: department, value: department }
                        : { label: "select", value: "" }
                    }
                    onChange={setDepartment}
                    options={DEPARTMENTVal.map((data) => ({
                      label: data.OM_DEPARTMENT_NAME,
                      value: data.OM_DEPARTMENT_NAME,
                    }))}
                  />
                </div>
                <div className="mb-4 lg:w-[48%] w-[90%] z-[800]">
                  <label className="block text-gray-700 font-bold mb-2">
                    VEHICLE ALLOCATED{" "}
                  </label>
                  <Select
                    name="VEHICLEALLOCATED"
                    className="w-full"
                    required
                    value={
                      vehicleAllocated
                        ? { label: vehicleAllocated, value: vehicleAllocated }
                        : { label: "select", value: "" }
                    }
                    onChange={setVehicleAllocated}
                    options={vehicles.map((vehi) => ({
                      label: vehi["VEHICLE"],
                      value: vehi["VEHICLE"],
                    }))}
                  />
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div className="mb-4  w-[90%]">
                  <div>
                    <label className="block text-gray-700 font-bold mb-2">
                      OVERTIME
                    </label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          // required
                          // defaultChecked={formInput.OVERTIME}
                          checked={formInput.OVERTIME}
                          type="radio"
                          name="OVERTIME"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          // required
                          checked={!formInput.OVERTIME}
                          type="radio"
                          name="OVERTIME"
                          defaultChecked={true}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        No
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-4  w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    FREEZE
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        checked={formInput.FREEZE}
                        name="FREEZE"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(1)}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        checked={!formInput.FREEZE}
                        name="FREEZE"
                        defaultChecked={true}
                        onChange={(e) => handleInputItem(e)}
                        value={Number(0)}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div className="mb-4  w-[90%]">
                  <label className="block text-gray-700 font-bold mb-2">
                    CASUAL
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="CASUAL"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "0") {
                            // If CASUAL is No, set SUBCONTRACTEDED to Yes
                            setFormInput({
                              ...formInput,
                              CASUAL: 0,
                              SUBCONTRACTED: 1,
                            });
                          } else {
                            // If CASUAL is Yes, set SUBCONTRACTEDED to No
                            setFormInput({
                              ...formInput,
                              CASUAL: 1,
                              SUBCONTRACTED: 0,
                            });
                          }
                        }}
                        value={1}
                        checked={formInput.CASUAL === 1}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        required
                        type="radio"
                        name="CASUAL"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "1") {
                            // If CASUAL is Yes, set SUBCONTRACTEDED to No
                            setFormInput({
                              ...formInput,
                              CASUAL: 1,
                              SUBCONTRACTED: 0,
                            });
                          } else {
                            // If CASUAL is No, set SUBCONTRACTEDED to Yes
                            setFormInput({
                              ...formInput,
                              CASUAL: 0,
                              SUBCONTRACTED: 1,
                            });
                          }
                        }}
                        value={0}
                        checked={formInput.CASUAL === 0}
                      />
                      No
                    </div>
                  </div>
                </div>
              </aside>
              <aside className="lg:flex justify-between">
                <div>
                  <label className="block text-gray-700 font-bold mb-2">
                    SUBCONTRACTED
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        required
                        className="radio"
                        type="radio"
                        name="SUBCONTRACTED"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "0") {
                            // If SUBCONTRACTEDED is No, set CASUAL to Yes
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 0,
                              CASUAL: 1,
                            });
                          } else {
                            // If SUBCONTRACTEDED is Yes, set CASUAL to No
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 1,
                              CASUAL: 0,
                            });
                          }
                        }}
                        value={1}
                        checked={formInput.SUBCONTRACTED === 1}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        required
                        className="radio"
                        type="radio"
                        name="SUBCONTRACTED"
                        onChange={(e) => {
                          handleInputItem(e);
                          if (e.target.value === "1") {
                            // If SUBCONTRACTEDED is Yes, set CASUAL to No
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 1,
                              CASUAL: 0,
                            });
                          } else {
                            // If SUBCONTRACTEDED is No, set CASUAL to Yes
                            setFormInput({
                              ...formInput,
                              SUBCONTRACTED: 0,
                              CASUAL: 1,
                            });
                          }
                        }}
                        value={0}
                        checked={formInput.SUBCONTRACTED === 0}
                      />
                      No
                    </div>
                  </div>
                </div>
                <div>
                  <label className="block text-gray-700 font-bold mb-2">
                    CONFIRM{" "}
                  </label>
                  <div className="radio_btn">
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="CONFIRM"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(1)}
                      />
                      Yes
                    </div>
                    <div>
                      <input
                        className="radio"
                        type="radio"
                        name="CONFIRM"
                        onChange={(e) => handleInputItem(e)}
                        value={Number(0)}
                        defaultChecked={true}
                      />
                      No
                    </div>
                  </div>
                </div>{" "}
              </aside>
              {filePreview ? <div>{renderFilePreview()}</div> : null}
              <div className="nxt mt-3">
                <button
                  className="View button"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset
                </button>

                <button className="View button bg-green" type="submit">
                  Register
                </button>
              </div>
            </form>
          </div>
        ) : null}
        <aside className="table__reference__app ">
          <div className="lg:flex gap-2 justify-between z-[500]">
            <div className="lg:flex items-center">
              <p>Search :</p>
              <input
                className="w-80 h-9 ml-2"
                type="text"
                // value={search ? search : ""}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </div>
            <div className="lg:flex z-20">
              <label className="label">DESIGNATION</label>
              <Select
                className="input_field text-left uppercase"
                // required
                // defaultValue={designation}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                value={
                  FilterDesignation
                    ? { label: FilterDesignation, value: FilterDesignation }
                    : { label: "select", value: "" }
                }
                onChange={(e) => setFilterDesignation(e.value)}
                options={[
                  { label: "", value: "" },
                  ...desigVal.map((data) => ({
                    label: data.OM_DESIGNATION_NAME,
                    value: data.OM_DESIGNATION_NAME,
                  })),
                ]}
              />
            </div>
            <div className="lg:flex z-20">
              <label className="label">DEPARTMENT</label>
              <Select
                className="input_field text-left uppercase"
                // required
                // defaultValue={department}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                value={
                  FilterDepartment
                    ? { label: FilterDepartment, value: FilterDepartment }
                    : { label: "select", value: "" }
                }
                onChange={(e) => setFilterDepartment(e.value)}
                options={[
                  { label: "", value: "" },
                  ...DEPARTMENTVal.map((data) => ({
                    label: data.OM_DEPARTMENT_NAME,
                    value: data.OM_DEPARTMENT_NAME,
                  })),
                ]}
              />
            </div>
            <div className="">
              <button
                className="lg:flex mt-2 lg:mt-0 items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-2 focus:outline-none focus:ring focus:border-blue-300 ml-2"
                onClick={handlePrint}
              >
                Print
              </button>
            </div>
          </div>
          {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
          <table className="custom-table ">
            <thead className="header-cell">
              <td>EMPLOYEE ID </td>
              <td>EMPLOYEE CODE</td>
              <td>EMPLOYEE NAME</td>
              <td>DATE JOINEDE</td>
              <td>NATIONAL ID NO</td>
              <td>TELEPHONE NO</td>
              <td>OVERTIME</td>
              <td>EMPLOYEE FREEZE</td>
              <td>DESIGNATION</td>
              <td>DEPARTMENT</td>
              <td>VEHICLE ALLOCATED</td>
              <td>SUPPLIER CODE</td>
              {/* <td>EXPIRY DATE</td> */}
              <td>EMAIL</td>
              <td>Edit</td>
            </thead>
            <tbody>
              {filteredData && filteredData.length > 0 ? (
                filteredData.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      // className={`table-row ${
                      //   selectedRow === index
                      //     ? "selected__row__stops__btn"
                      //     : "btn__cursor"
                      // }`}
                      // onClick={() => handleClick(index, item)}
                    >
                      <td>
                        <p>
                          {" "}
                          {item[" EMPLOYEE ID"] ? item[" EMPLOYEE ID"] : "N/A"}
                        </p>
                      </td>
                      <td>
                        <p>
                          {" "}
                          {item["EMPLOYEE CODE"]
                            ? item["EMPLOYEE CODE"]
                            : "N/A"}
                        </p>
                      </td>
                      <td>
                        {item["EMPLOYEE NAME"] ? item["EMPLOYEE NAME"] : "N/A"}
                      </td>
                      <td>
                        {item["DATE JOINED"]
                          ? moment(item["DATE JOINED"]).format("DD/MM/YYYY")
                          : "NA"}
                      </td>
                      <td>
                        {item["NATIONAL ID NO"]
                          ? item["NATIONAL ID NO"]
                          : "N/A"}
                      </td>
                      <td>
                        {item["TELEPHONE NO"] ? item["TELEPHONE NO"] : "0"}
                      </td>
                      <td>{item["OVERTIME"] ? "YES" : "NO"}</td>
                      <td>{item["EMPLOYEE FREEZE"] ? "YES" : "NO"}</td>
                      <td>
                        {item["DESIGNATION"] ? item["DESIGNATION"] : "N/A"}
                      </td>
                      <td>{item["DEPARTMENT"] ? item["DEPARTMENT"] : "N/A"}</td>
                      <td>
                        {item["VEHICLE ALLOCATED"]
                          ? item["VEHICLE ALLOCATED"]
                          : "N/A"}
                      </td>
                      <td>
                        {item["SUPPLIERCODE"] ? item["SUPPLIERCODE"] : "N/A"}
                      </td>

                      {/* <td>
                      {item["EXPIRY DATE"]
                        ? moment(item["EXPIRY DATE"]).format("DD/MM/YYYY")
                        : "NA"}
                    </td> */}
                      <td>{item["EMAIL"] ? item["EMAIL"] : "N/A"}</td>
                      <td
                        onClick={() => {
                          scrollToSection();
                          handleEdit(item);
                        }}
                      >
                        <BiSolidEditAlt />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11"> Not Data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </aside>
      </div>
      <div className="hidden print:block">
        <header className="header__of__main">
          <h1 className="header__of__page"> Employee Master</h1>
        </header>
        <table className="custom-table ">
          <thead className="custom-table uppercase text-black font-medium">
            <td>EMPLOYEE ID </td>
            <td>EMPLOYEE CODE</td>
            <td>EMPLOYEE NAME</td>
            <td>DATE JOINEDE</td>
            <td>NATIONAL ID NO</td>
            <td>TELEPHONE NO</td>
            <td>OVERTIME</td>
            <td>EMPLOYEE FREEZE</td>
            <td>DESIGNATION</td>
            <td>DEPARTMENT</td>
            <td>VEHICLE ALLOCATED</td>
            {/* <td>EXPIRY DATE</td> */}
            <td>EMAIL</td>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    // className={`table-row ${
                    //   selectedRow === index
                    //     ? "selected__row__stops__btn"
                    //     : "btn__cursor"
                    // }`}
                    // onClick={() => handleClick(index, item)}
                  >
                    <td>
                      <p>
                        {" "}
                        {item[" EMPLOYEE ID"] ? item[" EMPLOYEE ID"] : "N/A"}
                      </p>
                    </td>
                    <td>
                      <p>
                        {" "}
                        {item["EMPLOYEE CODE"] ? item["EMPLOYEE CODE"] : "N/A"}
                      </p>
                    </td>
                    <td>
                      {item["EMPLOYEE NAME"] ? item["EMPLOYEE NAME"] : "N/A"}
                    </td>
                    <td>
                      {item["DATE JOINED"]
                        ? moment(item["DATE JOINED"]).format("DD/MM/YYYY")
                        : "NA"}
                    </td>
                    <td>
                      {item["NATIONAL ID NO"] ? item["NATIONAL ID NO"] : "N/A"}
                    </td>
                    <td>{item["TELEPHONE NO"] ? item["TELEPHONE NO"] : "0"}</td>
                    <td>{item["OVERTIME"] ? "YES" : "NO"}</td>
                    <td>{item["EMPLOYEE FREEZE"] ? "YES" : "NO"}</td>
                    <td>{item["DESIGNATION"] ? item["DESIGNATION"] : "N/A"}</td>
                    <td>{item["DEPARTMENT"] ? item["DEPARTMENT"] : "N/A"}</td>
                    <td>
                      {item["VEHICLE ALLOCATED"]
                        ? item["VEHICLE ALLOCATED"]
                        : "N/A"}
                    </td>

                    {/* <td>
                      {item["EXPIRY DATE"]
                        ? moment(item["EXPIRY DATE"]).format("DD/MM/YYYY")
                        : "NA"}
                    </td> */}
                    <td>{item["EMAIL"] ? item["EMAIL"] : "N/A"}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="11"> Not Data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeMaster;
