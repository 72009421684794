import React, { useState, useEffect, useRef } from "react";
import MenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import moment from "moment";
import UploadLoader from "../../Components/Loader/UploadLoader";
import { MdDeleteForever } from "react-icons/md";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
const BookingReference = () => {
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [height, setHeight] = useState(500);

  const handleResize = (event, { size }) => {
    setHeight(size.height);
  };
  const state = useSelector((state) => state?.changeTheState);
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const userLogedIn = state?.user?.userName;
  const currentDate = new Date().toISOString();
  const ymdDate = currentDate.split("T")[0];
  const currentYear = currentDate?.slice(0, 4);
  const currentMonth = currentDate?.slice(5, 7);
  const currentDay = currentDate?.slice(8, 10);
  const datePass = currentDay + "/" + currentMonth + "/" + currentYear;
  const authToken = window.sessionStorage.getItem("auth-token");
  const [activeTab, setActiveTab] = useState(1);
  const [safariDetails, setSafariDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUPLoading, setIsUPLoading] = useState(false);
  const [ButtonBlock, setButtonBlock] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [excelDataLength, setExcelDataLength] = useState(0);

  const [fieldNames, setFieldNames] = useState([]);
  const fileInputRef = useRef(null);
  const [BookingIdPass, setBookingIdPass] = useState("");
  const [TransationdPass, setTransationdPass] = useState("");
  const [NEWFORMATEDDATA, setNEWFORMATEDDATA] = useState([]);
  const [fileName, setFileName] = useState(""); // Add state for file name
  const [sheetName, setSheetName] = useState(""); // Add state for sheet name
  const [newRows, setNewRows] = useState([]);
  const [AGENTData, setAGENTData] = useState([]);
  console.log(newRows);
  const handleAgentSelection = (index, selectedOption) => {
    handleNewRowChange(index, "AGENT", selectedOption.label);
  };
  const addNewRow = () => {
    const emptyRow = fieldNames.reduce((acc, fieldName) => {
      acc[fieldName] = "";
      return acc;
    }, {});
    setNewRows([...newRows, emptyRow]);
  };

  const handleNewRowChange = (index, fieldName, value) => {
    const updatedRows = newRows.map((row, i) =>
      i === index ? { ...row, [fieldName]: value } : row
    );
    setNewRows(updatedRows);
  };

  const removeNewRow = (index) => {
    setNewRows(newRows.filter((row, i) => i !== index));
  };

  const validateRows = (rows) => {
    console.log(NEWFORMATEDDATA);
    const requiredFields = [
      "DATE",
      "REF",
      "TIME",
      "PAX",
      "CARS",
      "PICK UP",
      "DROP OFF ",
      "NOTES / REMARKS",
      "AGENT",
    ];
    let isValid = true;
    let missingFieldsMessage = "";

    if (!rows) {
      // Handle the case where rows is undefined or null
      return {
        isValid: false,
        missingFieldsMessage: "Rows are undefined or null",
      };
    }

    rows.forEach((row, rowIndex) => {
      requiredFields.forEach((field) => {
        if (!row || !row[field]) {
          isValid = false;
          missingFieldsMessage += `Row ${
            excelDataLength + rowIndex + 1
          } is missing ${field}\n`;
        }
      });
    });

    return { isValid, missingFieldsMessage };
  };

  async function pushBulkExcelData() {
    if (NEWFORMATEDDATA.length < 1 && newRows.length < 1) {
      alert("Excel data is empty. Please provide a excel.");
      return;
    }

    const { isValid, missingFieldsMessage } = validateRows(newRows);

    if (!isValid) {
      alert(missingFieldsMessage);
      return;
    }

    const formatDate = (dateStr) => {
      const dateParts = dateStr?.split("/");
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    };

    const combinedData = [
      ...NEWFORMATEDDATA.bookingItems,
      ...newRows.map((row) => {
        const headers = {
          BOOKINGTRANSID: Number(TransationdPass),
          BOOKINGTRANSDATE: formatDate(datePass),
          BOOKINGTRANSBY: userLogedIn,
          BOOKINGDATE: row["DATE"] || "",
          BOOKINGREFNO: row["REF"] || "",
          FROMCOUNTRY: "",
          SUPPLIERNAME: "",
          TRANSFERORSAFARI: "",
          BOOKINGDESCRIPTION: row["NOTES / REMARKS"] || "",
          NOOFCARS: Number(row["CARS"]) || 0,
          ADULTCOUNT: Number(row["PAX"]) || 0,
          CHILDCOUNT: 0,
          INFANTCOUNT: 0,
          PICKUPTIME: row["TIME"] || "",
          DROPOFFTIME: "",
          PICKUPPLACE: row["PICK UP"] || "",
          DROPOFFPLACE: row["DROP OFF "] || "",
          FLIGHTNO: row["FLIGHT NUMBER"] || "",
          BOOKINGNOTES: row["NOTES / REMARKS"] || "",
          BOOKINGAGENTNAME: row["AGENT"] || "",
          CLIENTCODE: row["CLIENT NAME"] || "",
          BOOKINGMONTH: 0,
          BOOKINGYEAR: 0,
          CLIENTLANGUAGE: "",
          EXCELSHEETNAME: sheetName || "",
        };
        const values = {
          BOOKINGTRANSID: Number(TransationdPass),
          BOOKINGREFNO: row["REF"] || "",
          BOOKINGVEHICLE: "",
          PLANPICKUPDATE: row["DATE"] || "",
          PLANPICKUPTIME: row["TIME"] || "",
          BOOKINGNARRATION: row["NOTES / REMARKS"] || "",
        };

        return {
          tripHeader: headers,
          tripItem: values,
        };
      }),
    ];

    const confirmed = window.confirm(
      `Are you sure you want to submit the Excel data?`
    );
    console.log(combinedData);
    if (confirmed) {
      setIsUPLoading(true);
      try {
        const response = await axios.post(
          `${BASEURL}/insertBookings`,
          {
            bookingItems: combinedData,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(response.data.message);
        setIsUPLoading(false);
        if (response.data.message === "BOOKINGS SAVED") {
          setButtonBlock(true);
          window.location.reload();
        }
      } catch (err) {
        alert(err.response.data.message);
        setIsUPLoading(false);
      }
    }
  }

  console.log(NEWFORMATEDDATA);

  const [header, setHeaderData] = useState({});
  const [tabbleValues, setTableValues] = useState({});
  function getBookingID() {
    axios
      // .get(`${BASEURL}/getSafariBookingId`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=374`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data[0].NEXTNO, "getSafariBookingId");
        setBookingIdPass(response.data[0].NEXTNO);
      })
      .catch((error) => console.error(error));
  }
  function transationIdFetch() {
    setIsLoading(true);

    axios
      // .get(`${BASEURL}/getNewtransactionId`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=375`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.datayyy);
        setTransationdPass(response.data[0].NewTransID);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }
  const fetchBookingData = async () => {
    try {
      const getAgentsResponse = await axios
        // .get(`${BASEURL}/getAgents`, {
        .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=549`, {
          headers: { "auth-token": authToken, "session-token": sessiontoken },
        });
      setAGENTData(
        getAgentsResponse.data.map((item) => ({
          label: item["AGENTNAME"].trim(),
          value: item["AGENTCODE"],
        }))
      );
      //console.log(getAgentsResponse.data);
    } catch (error) {
      console.error("Error fetching AGENT data:", error);
    }
  };
  useEffect(() => {
    getBookingID();
    transationIdFetch();
    fetchBookingData();
  }, []);
  //console.log(NEWFORMATEDDATA);
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Trigger file input click when button is clicked
  };
  function formatTimeFromExcelSerial(serial) {
    const timeInMinutes = Math.round((serial % 1) * 24 * 60);
    let hours = Math.floor(timeInMinutes / 60);
    let minutes = timeInMinutes % 60;

    if (minutes === 60) {
      hours += 1;
      minutes = 0;
    }

    hours = hours % 24; // Ensures hours wrap around if they exceed 24

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  //console.log(ymdDate);
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFileName(file.name); // Set the file name

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        const sheetName = workbook.SheetNames[0];
        setSheetName(sheetName); // Set the sheet name

        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const currentFieldNames = jsonData[0];
        setFieldNames(currentFieldNames);

        const formattedData = jsonData
          ?.slice(1)
          ?.map((row) => {
            const rowData = {};
            currentFieldNames.forEach((fieldName, index) => {
              if (fieldName === "DATE") {
                rowData[fieldName] =
                  formatDateFromExcelSerial(row[index]) || "";
              } else if (fieldName === "TIME" || fieldName === "TIME") {
                rowData[fieldName] =
                  row[index] !== undefined &&
                  row[index] !== null &&
                  row[index] !== ""
                    ? formatTimeFromExcelSerial(row[index])
                    : "";
              } else if (fieldName === "ad/ch/inf") {
                rowData[fieldName] = /^\d{5}$/.test(row[index])
                  ? formatDateFromExcelSerial(row[index]) || ""
                  : row[index] || "";
              } else {
                if (fieldName === "Agent" && row[index] !== undefined) {
                  rowData[fieldName] = row[index].trim() || "";
                } else {
                  rowData[fieldName] = row[index] || "";
                }
              }
            });
            return rowData;
          })
          .filter(
            (row) =>
              row["REF"] !== undefined &&
              row["REF"] !== null &&
              row["REF"] !== ""
          );

        setExcelData(formattedData);
        setExcelDataLength(formattedData?.length);
        //console.log(formattedData);

        const formatDate = (dateStr) => {
          const dateParts = dateStr?.split("/");
          //console.log(dateParts);
          return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        };

        const bookingItems = formattedData?.map((row, index) => {
          const headers = {
            BOOKINGTRANSID: Number(TransationdPass),
            BOOKINGTRANSDATE: formatDate(datePass),
            BOOKINGTRANSBY: userLogedIn,
            BOOKINGDATE: formatDate(row["DATE"] || ""),
            BOOKINGREFNO: row["REF"] || "",
            FROMCOUNTRY: "",
            SUPPLIERNAME: "",
            TRANSFERORSAFARI: "",
            BOOKINGDESCRIPTION: row["NOTES / REMARKS"] || "",
            NOOFCARS: Number(row["CARS"]) || 0,
            ADULTCOUNT: Number(row["PAX"]) || 0,
            CHILDCOUNT: 0,
            INFANTCOUNT: 0,
            PICKUPTIME: row["TIME"] || "",
            DROPOFFTIME: "",
            PICKUPPLACE: row["PICK UP"] || "",
            DROPOFFPLACE: row["DROP OFF "] || "",
            FLIGHTNO: row["FLIGHT NUMBER"] || "",
            BOOKINGNOTES: row["NOTES / REMARKS"] || "",
            BOOKINGAGENTNAME: row["AGENT"] || "",
            CLIENTCODE: row["CLIENT NAME"] || "",
            BOOKINGMONTH: 0,
            BOOKINGYEAR: 0,
            CLIENTLANGUAGE: "",
            EXCELSHEETNAME: sheetName || "",
          };
          const values = {
            BOOKINGTRANSID: Number(TransationdPass),
            BOOKINGREFNO: row["REF"] || "",
            BOOKINGVEHICLE: "",
            PLANPICKUPDATE: formatDate(row["DATE"]) || "",
            PLANPICKUPTIME: row["TIME"] || "",
            BOOKINGNARRATION: row["NOTES / REMARKS"] || "",
          };

          return {
            tripHeader: headers,
            tripItem: values,
          };
        });

        setNEWFORMATEDDATA({ bookingItems });
      };

      reader.readAsBinaryString(file);
    }
  };

  function formatDateFromExcelSerial(serial) {
    // Convert Excel date (serial) to JavaScript date
    const excelDate = new Date((serial - 25569) * 24 * 60 * 60 * 1000); // Adjust for Excel's base date

    // Get the components of the date
    const day = excelDate.getDate().toString().padStart(2, "0");
    const month = (excelDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = excelDate.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  console.log(excelData);
  function formatTime(decimalTime) {
    if (typeof decimalTime === "number") {
      let hours = Math.floor(decimalTime * 24);
      let minutes = Math.round((decimalTime * 24 * 60) % 60);
      return (
        String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0")
      );
    }
    return decimalTime;
  }
  const renderList = (
    <div className="min-h-[100vh] ">
      <aside className="lg:flex justify-between px-2 items-center">
        <div className="lg:flex items-center gap-2 lg:mx-4 px-2">
          <p>Posted Date :</p>
          <p>
            <input
              type="date"
              value={currentDate?.slice(0, 10)}
              readOnly
              className="w-[120px]"
            />
          </p>
        </div>
        <div className="lg:flex items-center gap-2 lg:mx-4 px-2">
          <p>Posted By :</p>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <p>{userLogedIn ? userLogedIn : "N/A"}</p>
          )}
        </div>
        {ButtonBlock ? (
          <div className="flex items-center">
            <button
              className="bg-slate-300 px-2 h-[30px] m-1 rounded block "
              onClick={() => {
                window.location.reload();
              }}
            >
              New{" "}
            </button>
            <button className="bg-slate-300 px-2 h-[30px] m-1 rounded block">
              Save Bulk
            </button>
          </div>
        ) : (
          <div>
            {" "}
            {isUPLoading ? (
              <UploadLoader />
            ) : (
              <div className="flex items-center">
                <button
                  className="bg-slate-300 px-2 h-[30px] m-1 rounded block "
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  New{" "}
                </button>
                <button
                  className="bg-slate-300 px-2 h-[30px] m-1 rounded block"
                  onClick={pushBulkExcelData}
                >
                  Save Bulk
                </button>
              </div>
            )}
          </div>
        )}
      </aside>
      <aside className="lg:flex justify-between px-2 items-center w-full ">
        <p className="lg:flex items-center gap-1 w-full">
          <div className="lg:flex items-center gap-2 lg:mx-4 px-2  ">
            <p className="lg:w-[120px]">Transaction ID :</p>
            <p className="lg:w-[60px]">
              {TransationdPass ? TransationdPass : "N/A"}
            </p>
          </div>

          <label>Excel Name</label>
          <input
            className="w-[95%] lg:w-[400px]"
            value={fileName}
            readOnly
          ></input>

          <button
            className="button_style__pop my-1"
            onClick={handleButtonClick}
          >
            Choose File
          </button>
          <input
            type="file"
            id="chooseFile"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: "none" }}
            accept=".xlsx, .xls"
          />

          {/* <input style={{ width: "100px" }}></input> */}
        </p>
        {/* <p
            style={{
              display: "lg:flex",
              alignItems: "center",
            }}
          >
            <button className="bg-slate-300 px-2 h-[30px] m-1 rounded block ">
              Generate from Excel
            </button>
          </p>
          <p>Invalid Items: 0</p>
          <p
            style={{
              display: "lg:flex",
              alignItems: "center",
            }}
          >
            <button className="bg-slate-300 px-2 h-[30px] m-1 rounded block ">
              Validate Excel data
            </button>
          </p>
          <p>
            <button className="bg-slate-300 px-2 h-[30px] m-1 rounded block ">
              <SiMicrosoftexcel />
            </button>
          </p> */}
      </aside>
      {fileName && (
        <main>
          <main className="lg:flex lg:flex-wrap justify-between overflow-auto">
            <ResizableBox
              width="100%"
              height={height}
              minConstraints={[100, 100]}
              maxConstraints={[Infinity, Infinity]}
              resizeHandles={["s"]}
              onResize={handleResize}
            >
              <div
                className="table__reference__app overflow-auto"
                style={{ height: "100%" }}
              >
                {excelData && (
                  <div>
                    {/* Render the table with headers and values */}

                    <table className="custom-table text-sm overflow__y__ relative">
                      <thead className="header-cell sticky  top-[0px] left-[10px]">
                        <tr>
                          <td>Sl.No</td>
                          {fieldNames?.map((cell, index) => (
                            <td
                              key={index}
                              style={{
                                padding: "5px",
                              }}
                            >
                              <p
                                style={{
                                  paddingTop: "5px",
                                  paddingBottom: "5px",
                                  width:
                                    cell === "Supplier"
                                      ? "200px"
                                      : cell === "Service"
                                      ? "200px"
                                      : cell === "Pick-up place"
                                      ? "250px"
                                      : cell === "Booking Status"
                                      ? "150px"
                                      : cell === "Service Status"
                                      ? "150px"
                                      : cell === "Flight No."
                                      ? "100px"
                                      : cell === "Phone"
                                      ? "150px"
                                      : cell === "DROP OFF "
                                      ? "200px"
                                      : cell === "PICK UP"
                                      ? "200px"
                                      : cell === "Drop-off time"
                                      ? "200px"
                                      : cell === "Notes"
                                      ? "300px"
                                      : "auto",
                                }}
                              >
                                {" "}
                                {cell}
                              </p>
                            </td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData?.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            // onClick={() => {
                            //   handleRowClick(row);
                            //   handleTabClick(1);
                            // }}
                            style={{
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              width:
                                row === "SUPPLIER" ||
                                row === "SERVICE" ||
                                row === "PICK UP" ||
                                row === "DROP OFF" ||
                                row === "NOTES"
                                  ? "400px"
                                  : "400px",
                            }}
                          >
                            <td>{rowIndex + 1}</td>
                            {fieldNames.map((fieldName, cellIndex) => (
                              <td
                                key={fieldName}
                                style={{
                                  paddingLeft: "5px ",
                                  paddingRight: "5px",
                                  cursor: "pointer",
                                  width:
                                    fieldName === "SUPPLIER" ||
                                    fieldName === "SERVICE" ||
                                    fieldName === "DROP OFF" ||
                                    fieldName === "PICK UP" ||
                                    fieldName === "NOTES"
                                      ? "400px"
                                      : "400px",
                                }}
                              >
                                {fieldName === "TIME" ||
                                fieldName === "Drop off time"
                                  ? formatTime(row[fieldName])
                                  : row[fieldName] !== undefined
                                  ? fieldName === "Adults" ||
                                    fieldName === "Children" ||
                                    fieldName === "Infant"
                                    ? row[fieldName] || 0
                                    : row[fieldName]
                                  : ""}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                      <tbody>
                        {newRows.map((row, rowIndex) => (
                          <tr key={rowIndex + excelData.length}>
                            <td onClick={() => removeNewRow(rowIndex)}>
                              <div className="flex items-center gap-1 cursor-pointer">
                                {" "}
                                {rowIndex + excelData.length + 1}
                                <p className="text-lg">
                                  <MdDeleteForever />
                                </p>
                              </div>
                            </td>
                            {fieldNames.map((fieldName) => (
                              <td key={fieldName}>
                                {fieldName === "DATE" ? (
                                  <input
                                    type="date"
                                    value={row[fieldName]}
                                    onChange={(e) =>
                                      handleNewRowChange(
                                        rowIndex,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : fieldName === "TIME" ? (
                                  <input
                                    type="time"
                                    value={row[fieldName]}
                                    onChange={(e) =>
                                      handleNewRowChange(
                                        rowIndex,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : fieldName === "PAX" ||
                                  fieldName === "CARS" ? (
                                  <input
                                    type="number"
                                    className="w-[50px]"
                                    value={row[fieldName]}
                                    onChange={(e) =>
                                      handleNewRowChange(
                                        rowIndex,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : fieldName === "AGENT" ? (
                                  <Select
                                    className="w-[200px]"
                                    options={AGENTData}
                                    onChange={(selectedOption) =>
                                      handleAgentSelection(
                                        rowIndex,
                                        selectedOption
                                      )
                                    }
                                    value={AGENTData.find(
                                      (agent) => agent.value === row["AGENT"]
                                    )}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    value={row[fieldName]}
                                    onChange={(e) =>
                                      handleNewRowChange(
                                        rowIndex,
                                        fieldName,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>{" "}
            </ResizableBox>
          </main>
          <div>
            <button onClick={addNewRow} className="button_style__pop my-5">
              + Add Row
            </button>
          </div>
        </main>
      )}
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default BookingReference;
