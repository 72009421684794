import React, { useState, useEffect } from "react";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import "../InternalTransfer/InternalTransfer.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";

function FuelVoucher() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const state = useSelector((state) => state?.changeTheState);
  //console.log(state?.user?.userName, "nav");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fuelVoucherNo, setFuelVoucherNo] = useState(0); // Assuming initial value is 0
  const [fuelLocationSelect, setFuelLocationsSelect] = useState([]);
  const [fuelType, setFuelType] = useState("");
  const [vehicleList, setVehicleList] = useState("");
  const [vehicleListFilter, setVehicleListFilter] = useState("");
  const [driverList, setDriverList] = useState("");
  const [driverALLdata, SETdriverALLdata] = useState("");
  const [selectedDriverCode, setSelectedDriverCode] = useState("");

  const [vehicleNo, setVehicleNo] = useState("");
  const [driverCode, setDriverCode] = useState("");
  const [driverName, setDriverName] = useState("");
  const [fuelLocationFilter, setFuelLocationFilter] = useState("");
  const [payMode, setPayMode] = useState("CARD");
  const [dateOfRequest, setDateOfRequest] = useState(
    new Date().toISOString().split("T")[0]
  ); // Current date as string
  const [fuelSupplier, setFuelSupplier] = useState("");
  const [fuelQty, setFuelQty] = useState(0); // Assuming initial value is 0
  const [fuelRate, setFuelRate] = useState(0); // Assuming initial value is 0
  const [fuelReqDate, setFuelReqDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [fuelReqBy, setFuelReqBy] = useState("AZIZ");
  const [kmReading, setKmReading] = useState(0);
  const [narration, setNarration] = useState("");
  const [FuelRequestData, setFuelRequestData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [FuelVoucherCount, setFuelVoucherCount] = useState([]);
  const [AllFuelQtyCount, setAllFuelQtyCount] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const payModesOptions = [
    { label: "CARD ", value: "CARD" },
    { label: "CASH", value: "CASH" },
    { label: "PESAPAL", value: "PESAPAL" },
    { label: "MPESA", value: "MPESA" },
  ];
  // const state = useSelector((state) => state.changeTheState);
  //console.log(state);

  // if (state) {
  //   setFuelReqBy(state?.user?.userName ? state?.user.userName : " ");
  // }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const customStyless2 = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  function gatFuelData() {
    setIsLoading(true);
    axios
      // .get(`${BASEURL}/newFuelRequestId`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=384`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelVoucherNo(response?.data[0]?.FUELREQUEST);
        //console.log(response.data[0]?.FUELREQUEST, "setFuelVoucherNo");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    //newFuelStations
    axios
      // .get(`${BASEURL}/newFuelStations`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=369`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelLocationsSelect(
          response.data.map((item) => ({
            label: item.FUELLOCATION,
            value: item.FUELLOCATION,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    //getVehicleList
    axios
      // .get(`${BASEURL}/getVehicleList`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=671`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVehicleList(
          response.data.map((item) => ({
            label: item["VEHICLE REG NO"],
            value: item["VEHICLE REG NO"],
          }))
        );
        setVehicleListFilter(
          response.data.map((item) => ({
            label: item["VEHICLE REG NO"],
            value: item["VEHICLE REG NO"],
          }))
        );
        //console.log(response.data, "setVehicleList");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    axios
      // .get(`${BASEURL}/getEmployeeListDriver`, {
      .get(
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=611&DESIGNATION=DRIVER`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setDriverList(
          response.data.map((item) => ({
            label: item["EMPLOYEE NAME"],
            value: item["EMPLOYEE NAME"],
          }))
        );
        //console.log(response.data, "setDriverList");
        SETdriverALLdata(response.data);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    axios
      // .get(`${BASEURL}/getfuelvouchers`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=360`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setFuelRequestData");
        setFuelRequestData(response.data ? response.data : "");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    axios
      .get(`${BASEURL}/getFuelVoucherCount`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setFuelRequestData");
        setFuelVoucherCount(response.data ? response.data : "");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
    axios
      .get(`${BASEURL}/getAllFuelCountOfQty`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setFuelRequestData");
        setAllFuelQtyCount(response.data ? response.data : "");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));

    //newFuelStations
    axios
      // .get(`${BASEURL}/newFuelStations`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=369`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setFuelLocationsSelect(
          response.data.map((item) => ({
            label: item.FUELLOCATION,
            value: item.FUELLOCATION,
          }))
        );
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    gatFuelData();
    setFuelReqDate(new Date().toISOString().split("T")[0]);
    setDateOfRequest(new Date().toISOString().split("T")[0]);
  }, []);

  const selectedVehicle = (selectedVEh) => {
    //console.log("Selected selectedVEh:", selectedVEh?.value);
    setVehicleNo(selectedVEh?.value);
  };
  const selectedDriver = (selectedDriver) => {
    const selectedDriverName = selectedDriver?.value || "";
    setDriverName(selectedDriverName);

    // Fetch the driver code based on the selected driver name
    const selectedDriverObject = driverALLdata.find(
      (driver) => driver["EMPLOYEE NAME"] === selectedDriverName
    );

    const selectedDriverCode = selectedDriverObject
      ? selectedDriverObject["EMPLOYEE CODE"]
      : "";
    setSelectedDriverCode(selectedDriverCode);
    setDriverCode(selectedDriverCode);
  };

  const handleDateOfRequestChange = (e) => {
    setDateOfRequest(e.target.value);
    setFuelReqDate(e.target.value);
  };
  const hardcodedFuelTypes = [
    { label: "FUEL SAFARI", value: "FUEL SAFARI" },
    { label: "TRANSFER", value: "TRANSFER" },
  ];

  const handlePayModeChange = (selectedOption) => {
    setPayMode(selectedOption.value);
  };
  const handleFuelTypeChange = (selectedOption) => {
    setFuelType(selectedOption.value);
  };
  //console.log(fuelReqBy, "fuelReqBy");
  //console.log(fuelType, "fuelType");
  //console.log(narration, "setNarration");
  //console.log(dateOfRequest, "dateOfRequest");
  //console.log(fuelReqDate, "fuelReqDate");
  //console.log(vehicleNo, "vehicleNo");
  //console.log(fuelQty, "fuelQty");
  //console.log(fuelRate, "fuelRate");
  //console.log(driverName, "driverName");
  //console.log(payMode, "payMode");
  async function updateStatus() {
    const requestData = {
      FUELVOUCHERNO: fuelVoucherNo,
      FUELTYPE: fuelType,
      VEHICLENO: vehicleNo,
      DRIVERCODE: driverCode,
      DRIVERNAME: driverName,
      // FUELLOCATION: fuelLocation,
      FUELQTY: fuelQty,
      FUELRATE: fuelRate,
      FUELREQDATE: fuelReqDate,
      FUELREQBY: fuelReqBy,
      KMREADING: kmReading,
      NARRATION: narration,
      PAYMODE: payMode, // replace with the actual variable for PAYMODE
    };

    //console.log("Request Data:", requestData);

    const confirmed = window.confirm(
      `Are you sure you want to submit the voucher?`
    );

    if (confirmed) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          // `${BASEURL}/insertFuelRequest`,
          `${BASEURL_GLOBAL}/globalSpHandler?spname=139`,

          {
            FUELVOUCHERNO: fuelVoucherNo,
            FUELTYPE: fuelType,
            VEHICLENO: vehicleNo,
            DRIVERCODE: driverCode,
            DRIVERNAME: driverName,
            // FUELLOCATION: fuelLocation,
            FUELQTY: fuelQty,
            FUELRATE: fuelRate,
            FUELREQDATE: fuelReqDate,
            FUELREQBY: fuelReqBy,
            KMREADING: kmReading ? kmReading : 0,
            NARRATION: narration,
            PAYMODE: payMode,
            SUCCESS_STATUS: "",
            ERROR_STATUS: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        //console.log(response);
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (err) {
        alert(err.response.data.message);
      }
    } else {
      alert("submission canceled by the user.");
    }
  }

  const handleCheckboxChange = (item) => {
    if (item.APPROVED !== "1" && item.APPROVED !== "0") {
      const updatedSelectedItems = selectedItems.includes(item)
        ? selectedItems.filter(
            (selectedItem) => selectedItem.VOUCHERNUMBER !== item.VOUCHERNUMBER
          )
        : [...selectedItems, item];

      setSelectedItems(updatedSelectedItems);
    }
  };

  //console.log(selectedItems);

  const calculateHours = (requestDate) => {
    const diffInMilliseconds = Math.abs(new Date(requestDate) - currentDate);
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  async function ApproveData(value) {
    //console.log(value);
    const confirmed = window.confirm(
      `Are you sure you want to submit the ${value}?`
    );

    if (confirmed) {
      setIsLoading(true);

      for (const selectedItem of selectedItems) {
        try {
          const response = await axios.post(
            // `${BASEURL}/approveFuelVoucher`,
            `${BASEURL_GLOBAL}/globalSpHandler?spname=61`,
            {
              FUELVOUCHERNO: selectedItem.VOUCHERNUMBER,
              APPROVEDQTY: selectedItem.REQUESTQTY,
              APPROVEDBY: state?.user?.userName ? state?.user.userName : " ",
              APPROVEDCANCEL: value,
              SUCCESS_STATUS: "",
              ERROR_STATUS: "",
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
          window.location.reload();
          //console.log(response.data);
        } catch (err) {
          console.error(err.response.data.message);
        }
      }

      // After processing all selected items, you might want to reset the state or perform other actions
      setIsLoading(false);
    } else {
    }
  }

  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    2
  );

  const [defaultFromDate, setDefaultFromDate] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [defaultToDate, setDefaultToDate] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const [selectedVehicleFilter, setSelectedVehicleFilter] = useState(null);
  const [selectedFuelTypeFilter, setSelectedFuelTypeFilter] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [fromDateFilter, setFromDateFilter] = useState(defaultFromDate);
  const [toDateFilter, setToDateFilter] = useState(defaultToDate);
  useEffect(() => {
    // Set default filter values
    setFromDateFilter(defaultFromDate);
    setToDateFilter(defaultToDate);
  }, []);
  const handleVehicleFilterChange = (selectedOption) => {
    setSelectedVehicleFilter(selectedOption);
  };

  const handleFuelTypeFilterChange = (selectedOption) => {
    setSelectedFuelTypeFilter(selectedOption);
  };
  const handleFuelLocationChange = (selectedOption) => {
    //console.log("Selected Fuel Type:", selectedOption?.value);
    setFuelLocationFilter(selectedOption);
  };
  const handleStatusFilterChange = (selectedOption) => {
    setSelectedStatusFilter(selectedOption);
  };

  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  const filteredData = FuelRequestData.filter((item) => {
    const isVehicleMatch =
      !selectedVehicleFilter || item.VEHICLE === selectedVehicleFilter.value;

    const isFuelTypeMatch =
      !selectedFuelTypeFilter || item.FUELTYPE === selectedFuelTypeFilter.value;
    const isFuelLoactionMatch =
      !fuelLocationFilter || item.LOCATION === fuelLocationFilter.value;

    const FuelStatusFilter = [
      { label: "CANCELLED", value: "CANCELLED" },
      { label: "APPROVED", value: "APPROVED" },
      { label: "PENDING", value: "PENDING" },
    ];

    const isStatusMatch =
      !selectedStatusFilter ||
      (selectedStatusFilter.value === "APPROVED" && item.APPROVED === true) ||
      (selectedStatusFilter.value === "CANCELLED" && item.APPROVED === false) ||
      (selectedStatusFilter.value === "PENDING" && item.APPROVED === null);

    const isDateRangeMatch =
      (!fromDateFilter ||
        new Date(item.REQUESTDATE) >= new Date(fromDateFilter)) &&
      (!toDateFilter || new Date(item.REQUESTDATE) <= new Date(toDateFilter));

    return (
      isVehicleMatch &&
      isFuelTypeMatch &&
      isStatusMatch &&
      isDateRangeMatch &&
      isFuelLoactionMatch
    );
  });
  const FuelTypesFilter = [
    { label: "SAFARI FUEL", value: "SAFARI FUEL" },
    { label: "TRANSFER", value: "TRANSFER" },
  ];
  const FuelStatusFilter = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "APPROVED", value: "APPROVED" },
    { label: "PENDING", value: "PENDING" },
  ];
  //
  const renderList = (
    <div>
      <ManagerMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">List of Fuel Vouchers </h1>
        <div className="flex items-center">
          <button
            className="md:m-2 my-1 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
            onClick={() => {
              window.location.reload();
            }}
          >
            Clear
          </button>
        </div>
      </header>

      <aside className="main mx-1">
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Vehicle</label>
          <div
            style={{
              width: "200px",
              margin: "5px",
            }}
          >
            <Select
              style={customStyless}
              options={vehicleListFilter}
              onChange={handleVehicleFilterChange}
            />
          </div>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Fuel Type</label>
          <div
            style={{
              width: "180px",
              margin: "5px",
            }}
          >
            <Select
              options={FuelTypesFilter}
              style={customStyless}
              onChange={handleFuelTypeFilterChange}
            />
          </div>
        </p>
        <p className=" block md:flex items-center">
          <label>Fuel Location </label>
          <div className="my-1 md:m-[5px] w-[220px]">
            {" "}
            <Select
              options={fuelLocationSelect}
              onChange={handleFuelLocationChange}
              style={customStyless2}
            ></Select>
          </div>
        </p>
        <p
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <label>Status</label>
          <div
            style={{
              width: "180px",
              margin: "5px",
            }}
          >
            <Select
              options={FuelStatusFilter}
              style={customStyless}
              onChange={handleStatusFilterChange}
            />
          </div>
        </p>
      </aside>
      <aside className="lg:flex gap-4 items-center mx-4 lg:mx-2">
        <div className="my-1 lg:flex">
          <p>From :</p>
          <input
            type="date"
            onChange={handleFromDateFilterChange}
            defaultValue={defaultFromDate}
          />
        </div>
        <div className="my-1 lg:flex">
          <p>To :</p>
          <input
            type="date"
            onChange={handleToDateFilterChange}
            defaultValue={defaultToDate}
          />
        </div>
      </aside>
      <div className="sticky top-[70px] flex justify-end pt-3 pr-3 lg:p-0 lg:mr-5 bg-white lg:bg-inherit ">
        <button
          className="w-[80px] py-1 rounded mx-1 bg-slate-300"
          type="submit"
          onClick={() => ApproveData("APPROVE")}
        >
          APPROVE
        </button>
        <button
          className="w-[80px] py-1 rounded mx-1 bg-slate-300"
          type="submit"
          onClick={() => ApproveData("CANCEL")}
        >
          CANCEL
        </button>
      </div>
      <aside className="table__reference__app flex justify-between text-sm ">
        <table class="custom-table">
          <thead class="header-cell">
            <td></td>
            <td>VOUCHER NO</td>
            <td>FUEL TYPE</td>
            <td>DRIVER </td>
            <td>VEHICLE</td>
            <td>FUEL LOCATION</td>
            <td>REQUESTED DATE</td>
            <td>REQUEST QTY</td>
            {/* <td>HOURS</td> */}
            <td>STATUS</td>
          </thead>

          <tbody>
            {filteredData.map((item, index) => (
              <tr class="table-row" key={index}>
                <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={selectedItems.some(
                      (selectedItem) =>
                        selectedItem.VOUCHERNUMBER === item.VOUCHERNUMBER
                    )}
                    disabled={item.APPROVED == "1" || item.APPROVED == "0"}
                  ></input>
                </td>
                <td className="alignleft">{item.VOUCHERNUMBER}</td>
                <td>{item.FUELTYPE}</td>
                <td>{item.DRIVERNAME}</td>
                <td>{item.VEHICLE}</td>
                <td>{item.LOCATION}</td>
                <td className="alignleft">
                  {item.REQUESTDATE
                    ? `${formattedDate(item.REQUESTDATE).slice(0, 10)} `
                    : "N/A"}
                </td>
                <td className="alignleft">
                  {item.REQUESTQTY
                    ? Number(item.REQUESTQTY).toFixed(2).toLocaleString()
                    : "N/A"}
                </td>

                {/* <td>{calculateHours(item.REQUESTDATE)}</td> */}
                <td>
                  {item.APPROVED == "1"
                    ? "APPROVED"
                    : item.APPROVED == "0"
                    ? "CANCELLED"
                    : "PENDING"}
                </td>
                {/* <td>{FuelVoucherCount[0].APPROVED}</td> */}
              </tr>
            ))}
            <tr className="table-row">
              <td colSpan="9" className="">
                <span className="flex gap-6">
                  <p>
                    Total Voucher Approved:{" "}
                    <b className="text-red-600">
                      {FuelVoucherCount[0] ? FuelVoucherCount[0].APPROVED : ""}
                    </b>
                  </p>
                  <p>
                    Total Voucher Pending:{" "}
                    <b className="text-red-600">
                      {FuelVoucherCount[0] ? FuelVoucherCount[0].PENDING : ""}
                    </b>
                  </p>

                  <p>
                    Total Voucher Cancelled:{" "}
                    <b className="text-red-600">
                      {FuelVoucherCount[0] ? FuelVoucherCount[0].CANCELLED : ""}
                    </b>
                  </p>

                  <p>
                    Total Quantity:{" "}
                    <b className="text-red-600">
                      {AllFuelQtyCount[0]
                        ? Number(AllFuelQtyCount[0].TOTAL)
                            .toFixed(2)
                            .toLocaleString()
                        : "N/A"}
                    </b>
                  </p>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
}

export default FuelVoucher;
