import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Select from "react-select";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import { useSelector } from "react-redux";
import Loading from "../../Components/Loading/Loading";
import { journeyStatusUpdate } from "../../Redux/Action/journeyStatusUpdate";
import { useNavigate } from "react-router-dom";
import { tripStatus } from "../../Redux/Action/TripStatus";
import { FaRegEdit } from "react-icons/fa";
import { GrPlan } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import { VscFeedback } from "react-icons/vsc";
const DriverAllTripDetails = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const dispatch = useDispatch();
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [search, setSearch] = useState("");
  const [driverAllTrip, setDriverAllTrips] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  //console.log(state?.user?.userCode);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [safariPlanData, setSafariPlanData] = useState([]);
  const [tripreferenecNumber, setTripReferenceNumber] = useState("");
  const [tripNumberpass, setTripnumberpass] = useState("");
  const [isOpen2, setIsOpen2] = useState(false);
  const openPopup2 = () => {
    setIsOpen2(true);
  };

  const closePopup2 = () => {
    setIsOpen2(false);
  };
  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 0,
    2
  );
  //console.log("First day of current month:", firstDayOfMonth);
  const [fromDateFilter, setFromDateFilter] = useState(
    firstDayOfMonth.toISOString().split("T")[0]
  );
  const [toDateFilter, setToDateFilter] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const statusOptions = [
    { value: "notStarted", label: "Not Started" },
    { value: "started", label: "Started" },
    { value: "completed", label: "Completed" },
  ];

  const getStatus = (item) => {
    if (
      item.STARTEDTRIP === null &&
      item.ENDEDTRIP === null &&
      item.STARTEDTRIPREP === null
    ) {
      return "notStarted";
    } else if (
      (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
      item.ENDEDTRIP === null
    ) {
      return "started";
    } else if (
      (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
      item.ENDEDTRIP === true
    ) {
      return "completed";
    } else {
      return "N/A";
    }
  };

  const driverTrip = () => {
    if (state?.user?.designation === "DRIVER") {
      setIsLoading(true);
      axios
        // .post(
        //   `${BASEURL}/getParticularDriverTrips`,
        //   {
        //     DRIVERCODE: state?.user?.userCode,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=266&DRIVERCODE=${state?.user?.userCode}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setDriverAllTrips(response.data);
          //console.log(response.data, "setDriverAllTrips");

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (state?.user?.designation === "REP") {
      setIsLoading(true);
      axios
        .post(
          `${BASEURL}/getParticularRepTrips`,
          {
            REPCODE: state?.user?.userCode,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setDriverAllTrips(response.data);
          //console.log(response.data, "setDriverAllTrips");

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    driverTrip();
  }, []);
  const handleFromDateFilterChange = (event) => {
    setFromDateFilter(event.target.value);
  };

  const handleToDateFilterChange = (event) => {
    setToDateFilter(event.target.value);
  };
  const filteredData = driverAllTrip.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch =
      searchData.length === 0 ||
      searchData.every((term) => {
        return [
          "BOOKINGID",
          "TRIPNUMBER",
          "REFNO",
          // "DRIVERNAME",
          "VEHICLEPLANNED",
          "PICKUPFROM",
          "DROPOFFPLACE",
          "STARTDATETIME",
          "ENDDATETIME",
          "STARTADDRESS",
          "ENDADDRESS",
        ].some((key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        });
      });

    const statusMatch =
      !selectedStatus || getStatus(item) === selectedStatus.value;
    // const isDateRangeMatch =
    //   ((!fromDateFilter ||
    //     new Date(item.PLANPICKUPDATE) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.PLANPICKUPDATE) <= new Date(toDateFilter))) ||
    //   ((!fromDateFilter ||
    //     new Date(item.STARTDATETIME) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.STARTDATETIME) <= new Date(toDateFilter))) ||
    //   ((!fromDateFilter ||
    //     new Date(item.ENDDATETIME) >= new Date(fromDateFilter)) &&
    //     (!toDateFilter ||
    //       new Date(item.ENDDATETIME) <= new Date(toDateFilter)));
    if (
      selectedStatus?.value === "notStarted" ||
      selectedStatus?.value === undefined
    ) {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.PLANPICKUPDATE) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.PLANPICKUPDATE) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch;
    }
    if (selectedStatus?.value === "started") {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.STARTDATETIME) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.STARTDATETIME) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch;
    }
    if (selectedStatus?.value === "notStarted") {
      const isDateRangeMatch =
        (!fromDateFilter ||
          new Date(item?.ENDDATETIME) >= new Date(fromDateFilter)) &&
        (!toDateFilter ||
          new Date(item?.ENDDATETIME) <= new Date(toDateFilter));
      return textMatch && statusMatch && isDateRangeMatch;
    }

    return textMatch && statusMatch;
  });
  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  function proceedToUpdate(item) {
    //console.log(item);
    let statusToPass;
    if (item.STARTEDTRIP === null && item.ENDEDTRIP === null) {
      statusToPass = "NOTYETSTARTED";
    } else if (item.STARTEDTRIP === true && item.ENDEDTRIP === null) {
      statusToPass = "INPROGRESS";
    } else if (item.STARTEDTRIP === true && item.ENDEDTRIP === true) {
      statusToPass = "COMPLETED";
    } else {
      statusToPass = "N/A";
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          //console.log("Latitude:", latitude);
          //console.log("Longitude:", longitude);
          const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.display_name) {
                const address = data.display_name;
                //console.log(address);
                const datas = {
                  dbData: item,
                  geoCod: `${latitude}, ${longitude}`,
                  address: address,
                  fromPage: "DriverAllTripDetails",
                };

                dispatch(journeyStatusUpdate(datas));

                dispatch(tripStatus(statusToPass));
                navigate("/DriverTripSubmission");
              } else {
                alert("No Address Found!");
              }
            });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    }
  }
  function fetchSafariPlanData(item, status) {
    //console.log(item.REFNO);
    //console.log(item.TRIPNUMBER);
    const referenceNumber = item.REFNO;
    const tripNumber = item.TRIPNUMBER;
    setTripReferenceNumber(item.REFNO);
    setTripnumberpass(item.TRIPNUMBER);
    if (referenceNumber === "") {
      alert("Journey REF NO not available");
      return;
    }
    if (tripNumber === "") {
      alert("Journey TRIP NUMBER not available");
      return;
    }
    if (referenceNumber && tripNumber) {
      axios
        .post(
          `${BASEURL}/getSafariPlan`,
          {
            BOOKINGREF: referenceNumber,
            TRIPNUMBER: tripNumber,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          openPopup2();
          //console.log(response.data);
          setSafariPlanData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page"> My Trip Details</h1>
      </header>
      <div className="m-4">
        <div className="m-1 md:m-4 md:flex gap-4">
          <div className="searchhhhhh mx-4">
            <p>Search&nbsp;</p>
            <input
              className="input_1"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <div className="search__icon">
              {" "}
              <FaSearch />
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-lg">Status&nbsp; </p>
            <div className="w-[200px] md:w-[200px] z-50 ">
              <Select
                options={statusOptions}
                value={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
          <section className="lg:flex gap-3 items-center  lg:px-[20px] ">
            <div className="lg:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">From&nbsp;</p>
              <input
                type="date"
                value={fromDateFilter}
                onChange={handleFromDateFilterChange}
              />
            </div>
            <div className="lf:ml-[10px] md:ml-0 flex items-center ">
              <p className="w-[60px] lg:w-auto py-1">To&nbsp;</p>
              <input
                type="date"
                value={toDateFilter}
                onChange={handleToDateFilterChange}
              />
            </div>
          </section>
          <div className="flex items-center">
            <button
              className="md:m-1 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <aside className="table__reference__app ">
        {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
        <table className="custom-table text-[12px]">
          <thead className="header-cell">
            <td>SNo</td>
            <td> PICK DATE</td>
            <td> PICK TIME</td>
            <td>Client </td>
            <td>REF NO</td>
            <td>PAX</td>
            <td>PICK UP POINT</td>
            <td>DROP OFF POINT</td>
            {state?.user?.designation === "REP" && <td>DRIVER </td>}

            {state?.user?.designation === "DRIVER" && <td>REP </td>}
            <td>REMARKS</td>
            <td>Agent </td>

            {/* <td>NARRATION</td>
            <td>Agent Name</td> */}
            {/*  */}
            {/* <td>BOOKING ID</td>

            <td>TRIP NUMBER</td>
            <td>REF NUMBER</td>

            <td>REP NAME</td>
            <td>Guest Name</td>
            <td>Total No of PAX</td>
            <td>AGENT NAME</td>
            <td>PLANNED PICKUP DATE & TIME</td>

            <td>PICK-UP FROM</td>
            <td>DROP-OFF PLACE</td>
            <td>NARRATION</td>
            <td>START DATE TIME</td>
            <td>END DATE TIME</td> */}
            {/* <td>START ADDRESS</td>
            <td>END ADDRESS</td> */}
            {/* <td>STATUS</td> */}
            <td>UPDATE TRIP</td>
            <td>JOURNEY PLAN</td>
            {/* <td>PAX </td> */}
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                let status;
                if (
                  item.STARTEDTRIP === null &&
                  item.ENDEDTRIP === null &&
                  item.STARTEDTRIPREP === null
                ) {
                  status = "YET TO START";
                } else if (
                  (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
                  item.ENDEDTRIP === null
                ) {
                  status = "STARTED";
                } else if (
                  (item.STARTEDTRIP === true || item.STARTEDTRIPREP === true) &&
                  item.ENDEDTRIP === true
                ) {
                  status = "COMPLETED";
                } else {
                  status = "N/A";
                }

                return (
                  <tr key={index} className="table-row cursor-pointer">
                    <td className="text-right">{index + 1}</td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? `${formattedDate(item.PLANPICKUPDATE).slice(0, 10)}`
                        : "N/A"}
                    </td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? ` ${item.PLANPICKUPTIME.slice(11, 19)}`
                        : "N/A"}
                    </td>{" "}
                    <td>
                      {" "}
                      {item["CLIENT NAME"]
                        ? item["CLIENT NAME"]
                        : "Not Available"}
                    </td>
                    <td>{item.REFNO ? item.REFNO : "N/A"}</td>
                    <td>
                      {(item["ADULT PAX"] ?? 0) +
                        (item["INFANT PAX"] ?? 0) +
                        (item["CHILD PAX"] ?? 0)}
                    </td>
                    <td>{item.PICKUPFROM ? item.PICKUPFROM : "N/A"}</td>
                    <td>{item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"}</td>
                    {state?.user?.designation === "REP" && (
                      <td>{item.DRIVERNAME ? item.DRIVERNAME : "N/A"}</td>
                    )}
                    {state?.user?.designation === "DRIVER" && (
                      <td>
                        <td>{item.REPNAME ? item.REPNAME : "N/A"}</td>{" "}
                      </td>
                    )}
                    <td>{item.NARRATION ? item.NARRATION : "N/A"}</td>
                    <td>
                      {" "}
                      {item["AGENT NAME"]
                        ? item["AGENT NAME"]
                        : "Not Available"}
                    </td>{" "}
                    {/* {item.VEHICLEPLANNED ? item.VEHICLEPLANNED : "N/A"} */}
                    {/*  */}
                    {/* <td className="text-right ">
                      <p className="pr-4">
                        {" "}
                        {item.BOOKINGID ? item.BOOKINGID : "N/A"}
                      </p>
                    </td>
                    <td className="text-right">
                      <p className="pr-4">
                        {" "}
                        {item.TRIPNUMBER ? item.TRIPNUMBER : "N/A"}{" "}
                      </p>
                    </td>
                    <td>{item.REFNO ? item.REFNO : "N/A"}</td> */}
                    {/* <td>
                      {" "}
                      {item["CLIENT NAME"]
                        ? item["CLIENT NAME"]
                        : "Not Available"}
                    </td>
                    <td>
                      {(item["ADULT PAX"] ?? 0) +
                        (item["INFANT PAX"] ?? 0) +
                        (item["CHILD PAX"] ?? 0)}
                    </td>
                    <td>
                      {" "}
                      {item["AGENT NAME"]
                        ? item["AGENT NAME"]
                        : "Not Available"}
                    </td>{" "}
                    <td>
                      {item.PLANPICKUPTIME
                        ? `${formattedDate(item.PLANPICKUPDATE).slice(
                            0,
                            10
                          )} ${item.PLANPICKUPTIME.slice(11, 19)}`
                        : "N/A"}
                    </td>
                    <td>{item.PICKUPFROM ? item.PICKUPFROM : "N/A"}</td>
                    <td>{item.DROPOFFPLACE ? item.DROPOFFPLACE : "N/A"}</td>
                    <td>{item.NARRATION ? item.NARRATION : "N/A"}</td>
                    <td>
                      {item.STARTDATETIME
                        ? `${formattedDate(item.STARTDATETIME)?.slice(
                            0,
                            10
                          )} ${item.STARTTIME?.slice(11, 19)}`
                        : "N/A"}
                    </td>
                    <td>
                      {item.ENDDATETIME
                        ? `${formattedDate(item.ENDDATETIME).slice(
                            0,
                            10
                          )} ${item.ENDTIME?.slice(11, 19)}`
                        : "N/A"}
                    </td> */}
                    {/* <td>{item.STARTADDRESS ? item.STARTADDRESS : "N/A"}</td>
                    <td>{item.ENDADDRESS ? item.ENDADDRESS : "N/A"}</td> */}
                    {/* <td>{status}</td> */}
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      <p
                        className="text-center"
                        onClick={() => {
                          proceedToUpdate(item);
                          scrollToSection();
                        }}
                      >
                        <FaRegEdit />
                      </p>
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      <p
                        onClick={() => {
                          fetchSafariPlanData(item, status);
                          scrollToSection();
                        }}
                      >
                        <GrPlan />
                      </p>
                    </td>
                    {/* <td>
                      {(item["ADULT PAX"] ?? 0) +
                        (item["INFANT PAX"] ?? 0) +
                        (item["CHILD PAX"] ?? 0)}
                    </td>

                    <td>{item["MONTH"] ? item["MONTH"] : "N/A"}</td> */}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="11">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </aside>
      <div>
        {isOpen2 && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                zIndex: 1000,
              }}
            ></div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",

                background: "white",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                zIndex: 1001,
              }}
              className="w-[98%] h-auto lg:w-[50%] p-[10px] lg:p-[20px]"
            >
              <div className="flex justify-between">
                {" "}
                <header className="text-2xl font-bold text-[#002d62] font-serif">
                  <h1 className=" ">JOURNEY PLAN</h1>
                </header>
                <button
                  onClick={closePopup2}
                  className=" font-bold text-2xl  text-blue-800"
                >
                  <IoMdClose />
                </button>
              </div>
              <section className="px-2 lg:flex gap-4">
                <div className="flex gap-2">
                  <p>BOOKING REFERENCE : </p>
                  <p className="font-semibold text-red-600">
                    {tripreferenecNumber}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p>TRIP NUMBER : </p>
                  <p className="font-semibold text-red-600">{tripNumberpass}</p>
                </div>
              </section>
              <aside className="table__reference__app ">
                {/* <h2 className="my-2 text-[22px] font-semibold">All Trip details</h2> */}
                <table className="custom-table ">
                  <thead className="header-cell">
                    <td>JOURNEY FROM </td>
                    <td>JOURNEY TO </td>
                    <td>START DATE </td>
                    <td>START TIME </td>
                  </thead>
                  <tbody>
                    {safariPlanData && safariPlanData.length > 0 ? (
                      safariPlanData.map((item, index) => {
                        return (
                          <tr key={index} className="table-row">
                            <td>
                              <p> {item.STARTFROM ? item.STARTFROM : "N/A"}</p>
                            </td>
                            <td>
                              <p> {item.ENDTO ? item.ENDTO : "N/A"}</p>
                            </td>
                            <td>
                              {item.STARTDATE
                                ? `${formattedDate(item.STARTDATE).slice(
                                    0,
                                    10
                                  )} `
                                : "N/A"}
                            </td>
                            <td>
                              {item.STARTTIME
                                ? ` ${item.STARTTIME.slice(11, 19)}`
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="11">Journey plan not available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </aside>
            </div>
          </>
        )}
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverAllTripDetails;
