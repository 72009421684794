import React, { useState, useEffect, useCallback } from "react";
import MenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import axios from "axios";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import Loading from "../../Components/Loading/Loading";

const SavedBooking = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const state = useSelector((state) => state?.changeTheState);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [tableValues, setTableValues] = useState([]);
  const [editValues, setEditValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function getBookingID() {
    setIsLoading(true);
    axios
      // .get(`${BASEURL}/getBookingHeaders`, {
      .get(
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=590`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);

        setTableValues(response.data);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getBookingID();
  }, []);

  const handleInputChange = (index, key, value) => {
    const newEditValues = { ...editValues };
    newEditValues[index] = { ...newEditValues[index], [key]: value };
    setEditValues(newEditValues);
    debouncedPostData(index);
  };

  const postData = (index) => {
    const dataToPost = editValues[index];
    if (dataToPost) {
      axios
        .post(`${BASEURL}/updateBookingHeader`, dataToPost, {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        })
        .then((response) => {
          //console.log(response.data);
        })
        .catch((error) => console.error(error));
    }
  };

  const debouncedPostData = useCallback(debounce(postData, 1000), [editValues]);

  const renderList = (
    <div>
      <MenuNavbar />
      <div className="overflow-auto m-2.5 p-2.5 rounded-md shadow-md text-xs uppercase bg-gray-100">
        <table className="custom-table w-[95%] m-1 border-separate border-spacing-0 overflow-hidden rounded-md">
          <thead className="header-cell">
            <tr>
              <td>PICKUP PLACE</td>
              <td>DROPOFF PLACE</td>
              <td>FLIGHT NO</td>
              <td>NOTES</td>
              <td>AGENT</td>
              <td>CLIENT CODE</td>
              <td>MONTH</td>
              <td>YEAR</td>
              <td>LANGUAGE</td>
            </tr>
          </thead>
          <tbody>
            {tableValues.length > 0 &&
              tableValues.map((item, index) => (
                <tr
                  key={index}
                  className="cursor-pointer table-row h-[32px] my-[5px] py-[10px] border-b-[0.3px] border-[#002d62]"
                  style={{ textTransform: "uppercase" }}
                >
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.PICKUPPLACE || item.PICKUPPLACE}
                      onChange={(e) =>
                        handleInputChange(index, "PICKUPPLACE", e.target.value)
                      }
                      className="w-[200px]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={
                        editValues[index]?.DROPOFFPLACE || item.DROPOFFPLACE
                      }
                      onChange={(e) =>
                        handleInputChange(index, "DROPOFFPLACE", e.target.value)
                      }
                      className="w-[200px]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.FLIGHTNO || item.FLIGHTNO}
                      onChange={(e) =>
                        handleInputChange(index, "FLIGHTNO", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      className="w-[300px] h-[50px] "
                      value={editValues[index]?.PICKUPNOTES || item.PICKUPNOTES}
                      onChange={(e) =>
                        handleInputChange(index, "PICKUPNOTES", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.AGENT || item.AGENT}
                      onChange={(e) =>
                        handleInputChange(index, "AGENT", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.CLIENTCODE || item.CLIENTCODE}
                      onChange={(e) =>
                        handleInputChange(index, "CLIENTCODE", e.target.value)
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.FORMONTH || item.FORMONTH}
                      onChange={(e) =>
                        handleInputChange(index, "FORMONTH", e.target.value)
                      }
                      className="w-[50px]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.FORYEAR || item.FORYEAR}
                      onChange={(e) =>
                        handleInputChange(index, "FORYEAR", e.target.value)
                      }
                      className="w-[50px]"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={editValues[index]?.LANGUAGE || item.LANGUAGE}
                      onChange={(e) =>
                        handleInputChange(index, "LANGUAGE", e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default SavedBooking;
