import React, { useState, useEffect, useRef } from "react";
import MenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import { FaEdit } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import Select from "react-select";
import axios from "axios";
const VehicleMovement = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [responseData, setResponseData] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [Vehicle, setVehicle] = useState([]);
  const [unitId, setUnitId] = useState("");
  // const [unitno, setunitno] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [passBASEURLDYNAMIC, setBASEURLDYNAMIC] = useState("");
  const [currentDateTime, setcurrentDateTime] = useState("");
  const [selectedvehicle, setselectedvehicle] = useState("");
  const [selectedvichiclevalue, setselectedvichiclevalue] = useState("");
  console.log(passBASEURLDYNAMIC);
  useEffect(() => {
    fetchTrackingEndpoint();
    ModuleInterface();
    // Function to format a number with leading zeros (e.g., 5 => "05")
    const formatWithLeadingZeros = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Get the current time and date
    const now = new Date();
    const currentHours = formatWithLeadingZeros(now.getHours());
    const currentMinutes = formatWithLeadingZeros(now.getMinutes());
    const formattedTime = `${currentHours}:${currentMinutes}`;

    const currentYear = now.getFullYear();
    const currentMonth = formatWithLeadingZeros(now.getMonth() + 1); // Months are zero-indexed
    const currentDay = formatWithLeadingZeros(now.getDate());
    const formattedDate = `${currentYear}-${currentMonth}-${currentDay}`;
    const currentSeconds = formatWithLeadingZeros(now.getSeconds());
    const formattedDateTime = `${currentDay}/${currentMonth}/${currentYear}T${currentHours}${currentMinutes}${currentSeconds}`;

    setCurrentTime(formattedTime);
    setCurrentDate(formattedDate);
    setcurrentDateTime(formattedDateTime);
  }, []);
  function ModuleInterface() {
    axios
      // .get(`${BASEURL}/getVehicleList`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=671`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        //console.log(response.data, "setSafariDetails");
        //console.log(response.data, "new");
        setVehicle(response ? response.data : "");
      });
  }
  function fetchTrackingEndpoint() {
    axios
      // .get(`${BASEURL}/getTrackingEndpoint`, {
      .get(`${BASEURL_GLOBAL}/globalViewHandler?viewname=539`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setBASEURLDYNAMIC(response.data[0].ENDPOINT);
        console.log(response.data[0].ENDPOINT, "fetchTrackingEndpoint");
      });
  }

  function fetchData() {
    //console.log(currentDateTime);
    //console.log(unitId);
    if (unitId.length > 0) {
      axios
        .post(
          // `https://mtt-ts-api.azurewebsites.net/tracking/getunitposition`,
          `https://devapi.tech23.net/tracking/getunitposition`,
          {
            // REQUESTEDDATE: "09/01/2024T000000",
            // REQUESTEDUNITID: "5BDFC8",
            // REQUESTEDDATE: "09/01/2024T000000",
            // REQUESTEDUNITID: "T298DST",
            REQUESTEDDATE: currentDateTime,
            REQUESTEDUNITID: unitId,
            baseUrl: passBASEURLDYNAMIC,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setResponseData(response.data);
          //console.log(response.data);
          if (
            response.data &&
            response.data.Result &&
            response.data.Result.Position
          ) {
            setLatitude(response.data.Result.Position.Latitude);
            setLongitude(response.data.Result.Position.Longitude);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }

  function Handleselectedvalue(selectedvalue) {
    // setUnitId("");
    setselectedvehicle(selectedvalue);
    const vehicle = selectedvalue;
    //console.log(selectedvalue);
    const unitId = Vehicle.find((item) => {
      if (item["VEHICLE REG NO"] === vehicle) {
        return item;
      }
    });
    setUnitId(unitId["UNITID"]);
    //console.log(unitId["UNITID"]);
  }
  function HandleUnitValue(selectedvalue) {
    setUnitId(selectedvalue);
    setselectedvichiclevalue(selectedvalue);
    const unitid = selectedvalue;
    const vehicle = Vehicle.find((item) => {
      if (item["UNITID"] === unitid) {
        return item;
      }
    });
    setselectedvehicle(vehicle["VEHICLE REG NO"]);
  }
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "400px",
      marginTop: "0px",
      minHeight: "initial",
      height: "45px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.590901110721!2d${
    longitude || "DEFAULT_LONGITUDE"
  }!3d${
    latitude || "DEFAULT_LATITUDE"
  }!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16706886b75b%3A0x2b28f244c07d61d2!2sISKCON%20Bangalore!5e0!3m2!1sen!2sin!4v1641493761550!5m2!1sen!2sin`;

  return (
    <div>
      <MenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page">Vehicle Movement</h1>
      </header>

      <section className="p-2 m-2 shadow-md bg-[#f4f1f4]">
        <aside className="for__vehicle__movement">
          {/* <p>
            <label>Transaction No</label>
            <input style={{ width: "100px" }}></input>
          </p> */}
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Vehicle</label>
            <div
              style={{
                width: "150px",
                margin: "5px",
              }}
            >
              {" "}
              <Select
                value={{ value: selectedvehicle, label: selectedvehicle }}
                // value={{}}
                onChange={(e) => {
                  Handleselectedvalue(e.value);
                }}
                options={Vehicle.map((item) => ({
                  label: item["VEHICLE REG NO"],
                  value: item["VEHICLE REG NO"],
                }))}
                type=""
              ></Select>
            </div>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Unit Id</label>
            <div
              style={{
                width: "150px",
                margin: "5px",
              }}
            >
              <Select
                onChange={(e) => HandleUnitValue(e.value)}
                type=""
                // value={{ value: unitId, label: unitId }}
                value={{
                  value: unitId ? unitId : selectedvichiclevalue,
                  label: unitId ? unitId : selectedvichiclevalue,
                }}
                options={Vehicle.map((item) => ({
                  label: item["UNITID"] !== "" ? item["UNITID"] : "n/a",
                  value: item["UNITID"] !== "" ? item["UNITID"] : "n/a",
                }))}
              ></Select>
            </div>
          </p>
          <p>
            <label>Current Time</label>
            &nbsp;<input type="time" value={currentTime}></input>
          </p>
          <p className="my-2">
            <label>Current Date</label>
            &nbsp;<input type="date" value={currentDate}></input>
          </p>
          {/* <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Driver Name</label>
            <input type=""></input>
          </p> */}
        </aside>
        <article className="flex justify-between">
          <p>
            <button
              type="submit"
              onClick={fetchData}
              className="button_style__tab"
            >
              Get Location
            </button>
          </p>
          <div className="flex items-center">
            <button
              className="md:m-2 px-3 py-1 bg-[#43505f] rounded text-white font-semibold"
              onClick={() => {
                window.location.reload();
              }}
            >
              Clear
            </button>
          </div>
        </article>
        {/* <aside className="for__vehicle__movement">
          <p>
            <label>Pick up location</label>
            <input type=""></input>
          </p>
          <p>
            <label>Drop location</label>
            <input type=""></input>
          </p>

          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <label>Pick Up</label>
            <input type="time"></input>
          </p>

          <p>
            <label>Drop Time</label>
            <input type="time"></input>
          </p>
        </aside> */}
        {/* <aside className="for__vehicle__movement">
          <p>
            <label>Current Time</label>
            <input type="time" value={currentTime}></input>
          </p>
          <p>
            <label>Current Date</label>
            <input type="date" value={currentDate}></input>
          </p>
          <p></p>
          <p>
            <button
              type="submit"
              onClick={fetchData}
              className="button_style__tab"
            >
              Get Location
            </button>
          </p>
        </aside> */}
      </section>
      <section className="flex gap-10">
        {responseData && responseData.Result && (
          <div className=" justify-between flex-wrap gap-15 px-5">
            <h1>Name: {responseData.Result.Name}</h1>
            <p>IMEI: {responseData.Result.Imei}</p>
            <p>Uid : {responseData.Result.Uid}</p>
            <p>
              <span>Address</span> : {responseData.Result.Position.Address}
            </p>
          </div>
        )}
        {responseData && responseData.Result && (
          <div className=" justify-between flex-wrap gap-15 px-5">
            <p>Engine Status: {responseData.Result.Position.EngineStatus}</p>
            <p>
              Speed: {responseData.Result.Position.Speed}
              {responseData.Result.Position.SpeedMeasure}
            </p>
            {/* Display other properties as needed */}
            <p>Latitude: {responseData.Result.Position.Latitude}</p>
            <p>Longitude: {responseData.Result.Position.Longitude}</p>
            {/* You can add more UI elements to display other properties */}
          </div>
        )}
      </section>
      <div className="flex justify-center w-[100%] map-container p-3">
        <iframe
          title="Embedded Map"
          src={mapSrc}
          width="100%"
          height="350"
          frameBorder="0"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
    </div>
  );
};

export default VehicleMovement;
