import React, { useState, useEffect } from "react";
import DriverMenuNavbar from "../../Components/Navbar/DriverMenuNavbar";
import "../InternalTransfer/InternalTransfer.css";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const DriverAttendence = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const state = useSelector((state) => state?.changeTheState);
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [unitIdFuel, setUnitIdFuel] = useState("");
  const [kmReadingGeo, setKmReadingGeo] = useState("");

  const [kenyaDateTimeShow, setKenyaDateTimeShow] = useState("");

  function gatFuelData() {
    setIsLoading(true);

    if (state?.user?.vehicle) {
      axios
        // .post(
        //   `${BASEURL}/getUnitID`,
        //   {
        //     VEHICLEREGNO: state?.user?.vehicle,
        //   },
        .get(
          `${BASEURL_GLOBAL}/globalViewHandler?viewname=671&VEHICLE REG NO=${state?.user?.vehicle}`,
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setUnitIdFuel(response.data[0].UNITID);
        })
        .catch((error) => console.error(error));
    }
  }

  async function fetchKmDataToPass(status, messageInOut) {
    const currentDate = new Date();
    const formattedDateTOPASS = currentDate.toLocaleDateString("en-GB"); // Adjust locale as needed
    const formattedTimeTOPASS = currentDate.toLocaleTimeString("en-GB"); // Adjust locale as needed

    if (Number(kmReadingGeo) < 1) {
      alert("Fuel KM cannot be less than 0");
      return;
    }
    if (kmReadingGeo) {
      const confirmed = window.confirm(
        `Are you sure you want to ${messageInOut}?`
      );

      if (confirmed) {
        setIsLoading(true);

        try {
          const response = await axios.post(
            `${BASEURL}/insertDriverRepAttendance`,
            {
              EMPCODE: state?.user?.userCode,
              DATE: formattedDateTOPASS,
              VEHICLE: state?.user?.vehicle,
              STATUS: status, // Passed status parameter here
              KMREADING: kmReadingGeo ? kmReadingGeo : 0,
              TIME: formattedTimeTOPASS,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );

          //console.log(response);
          alert(response.data.message);
          window.location.reload();
        } catch (err) {
          alert(err.response.data.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        alert("Submission canceled by the user.");
      }
    } else {
      alert("Fetching latest km reading try again.");
    }
  }

  useEffect(() => {
    gatFuelData();
    const currentDate = new Date().toISOString();
    //console.log("Using user's system datetime:", currentDate);
    setKenyaDateTimeShow(currentDate);

    // fetchData();
    setIsLoading(false);
  }, [unitIdFuel]);

  const renderList = (
    <div>
      <DriverMenuNavbar />
      <header className="header__of__main flex justify-between items-end px-3">
        <h1 className="header__of__page"> attendance</h1>
        <div className="my-1">
          {" "}
          <input
            type="text"
            disabled
            value={
              kenyaDateTimeShow
                ? kenyaDateTimeShow?.slice(8, 10) +
                  "-" +
                  kenyaDateTimeShow?.slice(5, 7) +
                  "-" +
                  kenyaDateTimeShow?.slice(0, 4)
                : "N/A"
            }
            className="mx-4 w-[100px]"
          />
          <button
            type="submit"
            onClick={() => navigate("/DriverAttendenceHistory")}
            style={{ backgroundColor: "#2f2f5a" }}
            className="text-white px-2 py-1 rounded"
          >
            {" "}
            Past Attendance
          </button>
        </div>
      </header>
      <div className="m-1 md:mx-5 text-lg bg-slate-100 p-2 rounded for__submit__driver uppercase">
        <div>
          <h2 className="md:w-[170px]">Driver Name : </h2>

          <h3>{state?.user?.userName}</h3>
        </div>

        <div>
          <h2 className="md:w-[170px]">Driver Code : </h2>

          <h3>{state?.user?.userCode}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">vehicle : </h2>

          <h3>{state?.user?.vehicle}</h3>
        </div>
        <div>
          <h2 className="md:w-[170px]">KM Reading : </h2>

          <h3>
            <input
              className=" w-[150px] text-right"
              type="number"
              onChange={(e) => {
                setKmReadingGeo(e.target.value);
              }}
              defaultValue={kmReadingGeo ? kmReadingGeo : "0"}
            ></input>
          </h3>
        </div>
      </div>
      <aside className="mx-4 my-1 font-semibold">
        <button
          className="w-[140px] px-2 py-1 rounded mx-1 bg-green-200 "
          onClick={() => {
            fetchKmDataToPass("START", "Clock In");
          }}
        >
          CLOCK IN{" "}
        </button>

        <button
          className="w-[140px] px-2 py-1 bg-red-200 rounded m-2"
          onClick={() => {
            fetchKmDataToPass("END", "Clock Out");
          }}
        >
          CLOCK OUT{" "}
        </button>
      </aside>
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default DriverAttendence;
