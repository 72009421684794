import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./VehicleMaster.css";
import { BiSolidUserAccount, BiUserPin } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { MdOutlineConfirmationNumber } from "react-icons/md";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { BsTruck } from "react-icons/bs";
import { PiEngine } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loading from "../../Components/Loading/Loading";
import ManagerMenuNavbar from "../../Components/Navbar/ManagerMenuNavbar";
import Select from "react-select";
import moment from "moment";

function VehicleMaster() {
  const BASEURL_GLOBAL = process.env.REACT_APP_BASEURL_GLOBAL;
  const state = useSelector((state) => state?.changeTheState);
  const LoggedInUserName = state?.user?.emailId?.split("@")[0].toUpperCase();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isloading, setisloading] = useState(true);
  const [vehicleRegNo, setVehiclRegNo] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleMakeSearch, setVehicleMakeSearch] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleModelSearch, setVehicleModelSearch] = useState("");
  const [modelVehicle, setModelVehicle] = useState([]);
  const [makeVehicle, setMakeVehicle] = useState([]);
  const [search, setSearch] = useState("");
  const [vehiclesList, setVehiclesList] = useState([]);
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [datePush, setDatePush] = useState(
    new Date()?.toISOString()?.slice(0, 10)
  );

  const filteredData = vehiclesList?.filter((item) => {
    const searchData = search?.toLowerCase()?.split(" ")?.filter(Boolean);
    const sDate = item.REGDATE;
    const eDate = item.REGDATE;

    if (
      searchData.length === 0 &&
      !startdate &&
      !endDate &&
      !vehicleMakeSearch &&
      !vehicleModelSearch
    ) {
      return true;
    }

    const textMatch =
      searchData?.length === 0 ||
      searchData.every((term) => {
        return ["VEHICLE", "VEHICLETYPE", "MAKE", "MODEL"].some((key) => {
          const columnValue = String(item[key])?.toLowerCase();
          return columnValue?.includes(term);
        });
      });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!endDate || eDate <= endDate);

    const vehicleMakeMatch =
      !vehicleMakeSearch ||
      !vehicleMakeSearch.length ||
      vehicleMakeSearch.toLowerCase() === item.MAKE.toLowerCase();

    const vehicleModelMatch =
      !vehicleModelSearch ||
      !vehicleModelSearch.length ||
      vehicleModelSearch.toLowerCase() === item.MODEL.toLowerCase();

    return textMatch && dateMatch && vehicleMakeMatch && vehicleModelMatch;
  });

  useEffect(() => {
    axios
      .get(
        // `${BASEURL}/getVehiclemakelist`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=672`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setMakeVehicle(response?.data);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error While fetching Make List", err);
      });

    axios
      .get(
        // `${BASEURL}/getVehiclemodelList`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=673`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setModelVehicle(response?.data);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error while fetching Model List", err);
      });

    axios
      .get(
        // `${BASEURL}/getVehicles`, {
        `${BASEURL_GLOBAL}/globalViewHandler?viewname=556`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setVehiclesList(response?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        //console.log("error while fetching Model List", err);
      });
  }, []);

  function saveVehicle() {
    const datePassingFormat =
      datePush.slice(8, 10) +
      "/" +
      datePush.slice(5, 7) +
      "/" +
      datePush.slice(0, 4);

    const confirmed = window.confirm("Are you sure you want to register?");
    if (confirmed) {
      setisloading(true);
      axios
        .post(
          `${BASEURL}/insertVehicle`,
          {
            VEHICLEREGNO: vehicleRegNo,
            VEHICLETYPE: vehicleType,
            REGISTRATIONDATE: datePassingFormat,
            VEHICLEMAKE: vehicleMake,
            VEHICLEMODEL: vehicleModel,
            CREATEDDBY: LoggedInUserName,
            CREATEDDATE: datePassingFormat,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          alert(response.data.message);
          setisloading(false);
          window.location.reload();
        })
        .catch(() => {
          alert("Internal server error! please try after some time");
          setisloading(false);
        });
    } else {
      setisloading(false);
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="block print:hidden">
        <ManagerMenuNavbar />
        <header
          className="header__of__main lg:flex justify-between w-full px-4 "
          style={{ margin: "0px", marginLeft: "0px" }}
        >
          <h1 className="header__of__page" style={{ margin: "0px" }}>
            {" "}
            Vehicle Master
          </h1>
          <div className="flex gap-2 items-center  my-1">
            <p>
              <button
                type="button"
                onClick={() => window.location.reload()}
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              >
                New
              </button>
            </p>
            <p>
              <button
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
                onClick={saveVehicle}
              >
                Save
              </button>
            </p>
            <p>
              <button
                onClick={() => window.print()}
                className=" bg-[#002d62]  text-white rounded-md px-2 h-[30px]  lg:ml-2 mr-2 "
              >
                Print
              </button>
            </p>
          </div>
        </header>
        <main className="bg-blue-50 m-1 rounded">
          <section className="p-3 lg:flex justify-between">
            <div className="lg:flex gap-1 items-center">
              <p className="w-[220px] lg:text-right">
                Vehicle Registration Number :
              </p>
              <input
                required
                type="text"
                value={vehicleRegNo}
                onChange={(e) => setVehiclRegNo(e.target.value)}
              />
            </div>
            <div className="lg:flex gap-1 items-center">
              <p> Vehicle Type :</p>
              <input
                required
                type="text"
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value)}
              />
            </div>
            <div className="lg:flex gap-1 items-center">
              <p> Date :</p>
              <input
                required
                value={datePush.split("T")[0]}
                onChange={(e) => setDatePush(e.target.value)}
                type="date"
              />
            </div>
          </section>
          <section className="p-3 lg:flex">
            <div className="lg:flex gap-1 items-center">
              <p className="lg:w-[220px] lg:text-right">Vehicle Make :</p>
              <Select
                className="lg:w-96"
                value={
                  vehicleMake
                    ? { label: vehicleMake, value: vehicleMake }
                    : { label: "Select Make", value: "" }
                }
                onChange={(e) => setVehicleMake(e.value)}
                options={[
                  { label: "Select Make", value: "" },
                  ...makeVehicle.map((item) => ({
                    label: item?.MAKE,
                    value: item?.MAKE,
                  })),
                ]}
              />
            </div>

            <div className="lg:flex gap-1 items-center lg:px-3">
              <p>Vehicle Model :</p>
              <Select
                className="lg:w-96"
                options={modelVehicle?.map((model) => ({
                  value: model.MODEL,
                  label: model.MODEL,
                }))}
                value={
                  vehicleModel
                    ? { label: vehicleModel, value: vehicleModel }
                    : { label: "Select Model", value: "" }
                }
                onChange={(e) => setVehicleModel(e.value)}
                placeholder="Select model"
              />
            </div>
          </section>
        </main>
        <article>
          <div>
            <section className="table__pos__app w-[98%]  p-3">
              <div className="lg:flex items-center">
                <p className="lg:mx-2 text-lg">Search</p>
                <input
                  className="w-[200px] lg:mr-[50px]"
                  required
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <p className="lg:mx-3">Start Date :</p>
                <input
                  type="date"
                  value={startdate?.split("T")[0]}
                  onChange={(e) => setStartDate(e.target.value)}
                ></input>
                <p className="lg:mx-3">End Date :</p>
                <input
                  type="date"
                  value={endDate?.split("T")[0]}
                  onChange={(e) => setEndDate(e.target.value)}
                ></input>
              </div>
              <div className="lg:flex items-center mt-4 mb-2">
                <p className="lg:mx-3">Vehicle Make :</p>
                <Select
                  className="lg:w-96"
                  value={
                    vehicleMakeSearch
                      ? { label: vehicleMakeSearch, value: vehicleMakeSearch }
                      : { label: "Select Make", value: "" }
                  }
                  onChange={(e) => setVehicleMakeSearch(e.value)}
                  options={[
                    { label: "Select Make", value: "" },
                    ...makeVehicle.map((item) => ({
                      label: item?.MAKE,
                      value: item?.MAKE,
                    })),
                  ]}
                />
                <p className="lg:mx-3">Vehicle Model :</p>
                <Select
                  className="lg:w-96"
                  options={[
                    { label: "Select Model", value: "" },
                    ...modelVehicle.map((item) => ({
                      label: item?.MODEL,
                      value: item?.MODEL,
                    })),
                  ]}
                  value={
                    vehicleModelSearch
                      ? { label: vehicleModelSearch, value: vehicleModelSearch }
                      : { label: "Select Model", value: "" }
                  }
                  onChange={(e) => setVehicleModelSearch(e.value)}
                  placeholder="Select model"
                />
              </div>
              <table class="custom-table w-[80%] m-2 ">
                <thead class="header-cell">
                  <tr>
                    <td> SNo</td>
                    <td> VEHICLE</td>
                    <td> VEHICLE TYPE</td>
                    <td> DATE</td>
                    <td> VEHICLE MAKE</td>
                    <td> VEHICLE MODEL</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(filteredData) && filteredData.length > 0 ? (
                    filteredData?.map((value, index) => (
                      <tr
                        key={index}
                        className="table-row cursor-pointer"
                        onClick={() => {
                          setVehiclRegNo(value?.VEHICLE);
                          setVehicleType(value.VEHICLETYPE);
                          setDatePush(value?.REGDATE);
                          setVehicleMake(value?.MAKE);
                          setVehicleModel(value?.MODEL);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                      >
                        <td>{index + 1}</td> {/* Add 1 to start index from 1 */}
                        <td>{value.VEHICLE ? value.VEHICLE : "N/A"}</td>
                        <td>{value.VEHICLETYPE ? value.VEHICLETYPE : "N/A"}</td>
                        <td>
                          {value.REGDATE
                            ? moment(value.REGDATE).format("DD/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td>{value.MAKE ? value.MAKE : "N/A"}</td>
                        <td>{value.MODEL ? value.MODEL : "N/A"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </section>
          </div>
        </article>
      </div>
      <div className="hidden print:block">
        <h1 className="text-2xl mb-2">Vehicle Master</h1>
        <table class="custom-table w-[80%] mt-2">
          <thead class="header-cell" className="text-black">
            <tr>
              <td> SNo</td>
              <td> VEHICLE TYPE</td>
              <td> DATE</td>
              <td> VEHICLE MAKE</td>
              <td> VEHICLE MODEL</td>
              <td> VEHICLE</td>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredData) && filteredData.length > 0 ? (
              filteredData?.map((value, index) => (
                <tr key={index} className="table-row cursor-pointer">
                  <td>{index + 1}</td> {/* Add 1 to start index from 1 */}
                  <td>{value.VEHICLETYPE ? value.VEHICLETYPE : "N/A"}</td>
                  <td>
                    {value.REGDATE
                      ? moment(value.REGDATE).format("DD/MM/YYYY")
                      : "N/A"}
                  </td>
                  <td>{value.MAKE ? value.MAKE : "N/A"}</td>
                  <td>{value.MODEL ? value.MODEL : "N/A"}</td>
                  <td>{value.VEHICLE ? value.VEHICLE : "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default VehicleMaster;
